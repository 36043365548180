import React from 'react';
import { object, string, bool, shape, element } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import get from 'lodash/get';
import { STATES_CONST } from '../../constants/states';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '527px',
    borderWidth: 2,
    borderColor: 'red',
    color: '#1C1F24',
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
    },
  },
  formControl: {
    flex: '0 45%',
    display: 'flex',
    height: '115px',
    margin: '0 16px',
  },
  errorMsg: {
    color: '#f44336',
    textAlign: 'left',
    maxWidth: '524px',
    width: '100%',
  },
}));

const AutoCompleteSelectStates = ({ label, children, input, meta: { touched, error }, ...custom }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={custom.className || classes.formControl}>
      <Autocomplete
        {...input}
        label={label}
        onChange={(event, value) => input.onChange(value)}
        options={STATES_CONST}
        placeholder={label}
        error={touched && !!error}
        helperText={touched && error}
        getOptionLabel={(option) => {
          const findElem = STATES_CONST.find((el) => el.name === get(option, 'name', option));
          return findElem ? findElem.name : '';
        }}
        {...custom}
        className={classes.root}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
      />
      <FormHelperText className={classes.errorMsg}>{touched && error}</FormHelperText>
    </FormControl>
  );
};

AutoCompleteSelectStates.propTypes = {
  input: object.isRequired,
  label: string,
  children: element.isRequired,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

AutoCompleteSelectStates.defaultProps = {
  label: null,
};

export default AutoCompleteSelectStates;
