import React from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { object, func, bool } from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { i18n } from '../../../constants/i18n';
import Input from '../../Input/Input';
import { editFormValidation } from '../../../utils/validators';
import Avatar from '../../Avatar/Avatar';
import {
  AddInfoAboutYourSelfSection,
  AddPersonalInfoSection,
  ButtonWrapper,
  CustomInputPhone,
  EditProfileFormWrapper,
} from './EditProfileFormStyles';
import DatePicker from '../../DatePicker/DatePicker';
import { ColumnViewWrapper } from '../../SignUp/SignUpStepTwo/AddPersonalInfo/AddPersonalInfoForm/AddPersonalInfoFormStyles';
import { FormTitle } from '../../../pages/Dashboard/EditProfilePage/EditProfileStyles';
import InputChip from '../../InputChip/InputChip';
import Autocomplete from '../../Select/SelectInput';
import AutoCompleteSelectStates from '../../AutoCompleteSelectStates/AutoCompleteSelectStates';
import AutoCompleteSelect from '../../AutoCompleteSelect/AutoCompleteSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 16px',
    height: '115px',
    [theme.breakpoints.up('lg')]: {
      flex: '0 45%',
      display: 'flex',
    },
  },
  formControlClass: {
    width: '100%',
    margin: '0 16px',
    height: '115px',
    [theme.breakpoints.up('lg')]: {
      flex: '0 45%',
      display: 'flex',
    },
  },
  inputMargin: {
    marginBottom: '60px',
    width: '100%',
    maxWidth: 'none',
  },
  formControl: {
    width: '100%',
    maxWidth: 'none',
    minHeight: '90px',
  },
  textField: {
    height: '175px',
  },
  customInput: {
    width: 'calc(100% - 66px)',
  },
  customInputPhoneCode: {
    width: 60,
    marginRight: 10,
  },
}));

const EditProfileForm = ({ setAvatar, therapistData, updateTherapist, handleSubmit, invalid }) => {
  const classes = useStyles();
  const onSubmit = (values) => {
    const valuesFormatted = {
      ...values,
      jobRole: values.jobRole.toUpperCase(),
      specializations: values.specializations.toString(),
      phone: `+44${values.phone}`,
      state: values.state.code,
    };
    updateTherapist({ ...therapistData, ...valuesFormatted });
  };
  return (
    <EditProfileFormWrapper>
      <FormTitle>{i18n.personalInfo}</FormTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AddPersonalInfoSection>
          <Avatar setAvatar={setAvatar} imageSrc={therapistData.imageS3Key} width="128px" height="128px" />
          <ColumnViewWrapper>
            <Field component={Input} className={classes.root} name="firstName" placeholder={i18n.yourName} />
            <Field component={Input} className={classes.root} name="lastName" placeholder={i18n.yourSurname} />
            <CustomInputPhone>
              <Field component={Input} disabled className={classes.customInputPhoneCode} label="+44" name="phoneCode" />
              <Field component={Input} className={classes.customInput} name="phone" placeholder={i18n.phoneNumber} />
            </CustomInputPhone>
            <Field
              component={DatePicker}
              className={classes.root}
              value={moment(therapistData.dateOfBirth)}
              name="dateOfBirth"
              placeholder={i18n.dateOfBirth}
            />
            <Field
              component={AutoCompleteSelect}
              label={i18n.postalCode}
              name="postalCode"
              id="postalCode"
              className={classes.formControlClass}
              placeholder={i18n.postalCode}
            />
            <Field component={Input} className={classes.root} name="city" placeholder={i18n.city} />
            <Field
              component={AutoCompleteSelectStates}
              className={classes.formControlClass}
              name="state"
              label={i18n.state}
              placeholder={i18n.state}
            />
            <Field component={Input} className={classes.root} name="address1" placeholder={i18n.yourAddressLine1} />
            <Field component={Input} className={classes.root} name="address2" placeholder={i18n.yourAddressLine2} />
          </ColumnViewWrapper>
        </AddPersonalInfoSection>
        <AddInfoAboutYourSelfSection>
          <Field
            fullWidth
            component={Autocomplete}
            className={classes.inputMargin}
            formControlClass={classes.formControl}
            name="jobRole"
            label={i18n.jobRole}
            placeholder={i18n.addRoleTitle}
            variant="outlined"
          >
            <MenuItem value="COUNSELLOR_PSYCHOTHERAPIST">{i18n.CounsellorPsychotherapist}</MenuItem>
            <MenuItem value="CLINICAL_PSYCHOLOGIST">{i18n.ClinicalPsychologist}</MenuItem>
          </Field>
          <Field
            fullWidth
            component={Input}
            className={classes.textField}
            name="description"
            label={i18n.describeYourSelf}
            placeholder={i18n.yourDescription}
            multiline
            rows={4}
            variant="outlined"
          />
          <Field
            name="specializations"
            component={InputChip}
            className={classes.inputMargin}
            label={i18n.yourSpecialization}
            placeholder={i18n.countOfYourSkills}
          />
        </AddInfoAboutYourSelfSection>
        {!invalid && (
          <ButtonWrapper>
            <button type="submit">{i18n.saveChanges}</button>
          </ButtonWrapper>
        )}
      </Form>
    </EditProfileFormWrapper>
  );
};

EditProfileForm.propTypes = {
  setAvatar: func.isRequired,
  therapistData: object.isRequired,
  updateTherapist: func.isRequired,
  handleSubmit: func.isRequired,
  invalid: bool,
};
EditProfileForm.defaultProps = {
  invalid: false,
};

export default reduxForm({
  form: 'editForm',
  destroyOnUnmount: false,
  validate: editFormValidation,
})(EditProfileForm);
