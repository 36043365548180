import React from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { func, bool } from 'prop-types';
import { i18n } from '../../../constants/i18n';
import { FieldWrapper } from '../../Dialog/DialogStyles';
import PasswordInput from '../../PasswordInput/PasswordInput';
import { changePasswordFormValidation } from '../../../utils/validators';
import { ButtonWrapper } from '../EditProfileForm/EditProfileFormStyles';
import { ChangePasswordFormWrapper } from './ChangePasswordFormStyles';

const ChangePasswordForm = ({ handleSubmit, changePassword, invalid }) => {
  return (
    <ChangePasswordFormWrapper>
      <span>{i18n.changePassword}</span>
      <Form onSubmit={handleSubmit(changePassword)}>
        <FieldWrapper>
          <Field component={PasswordInput} name="currentPassword" placeholder={i18n.currentPassword} />
          <Field component={PasswordInput} name="newPassword" placeholder={i18n.newPassword} />
        </FieldWrapper>
        {!invalid && (
          <ButtonWrapper>
            <button type="submit">{i18n.saveChanges}</button>
          </ButtonWrapper>
        )}
      </Form>
    </ChangePasswordFormWrapper>
  );
};

ChangePasswordForm.propTypes = {
  changePassword: func.isRequired,
  handleSubmit: func.isRequired,
  invalid: bool,
};
ChangePasswordForm.defaultProps = {
  invalid: false,
};

export default reduxForm({
  form: 'changePasswordForm',
  validate: changePasswordFormValidation,
})(ChangePasswordForm);
