export const STATES_CONST = [
  { code: 'I0', name: 'Aberconwy and Colwyn' },
  { code: 'I1', name: 'Aberdeen City' },
  { code: 'I2', name: 'Aberdeenshire' },
  { code: 'I3', name: 'Anglesey' },
  { code: 'I4', name: 'Angus' },
  { code: 'I5', name: 'Antrim' },
  { code: 'I6', name: 'Argyll and Bute' },
  { code: 'I7', name: 'Armagh' },
  { code: 'I8', name: 'Avon' },
  { code: 'I9', name: 'Ayrshire' },
  { code: 'IB', name: 'Bath and NE Somerset' },
  { code: 'IC', name: 'Bedfordshire' },
  { code: 'IE', name: 'Belfast' },
  { code: 'IF', name: 'Berkshire' },
  { code: 'IG', name: 'Berwickshire' },
  { code: 'IH', name: 'BFPO' },
  { code: 'II', name: 'Blaenau Gwent' },
  { code: 'IJ', name: 'Buckinghamshire' },
  { code: 'IK', name: 'Caernarfonshire' },
  { code: 'IM', name: 'Caerphilly' },
  { code: 'IO', name: 'Caithness' },
  { code: 'IP', name: 'Cambridgeshire' },
  { code: 'IQ', name: 'Cardiff' },
  { code: 'IR', name: 'Cardiganshire' },
  { code: 'IS', name: 'Carmarthenshire' },
  { code: 'IT', name: 'Ceredigion' },
  { code: 'IU', name: 'Channel Islands' },
  { code: 'IV', name: 'Cheshire' },
  { code: 'IW', name: 'City of Bristol' },
  { code: 'IX', name: 'Clackmannanshire' },
  { code: 'IY', name: 'Clwyd' },
  { code: 'IZ', name: 'Conwy' },
  { code: 'J0', name: 'Cornwall/Scilly' },
  { code: 'J1', name: 'Cumbria' },
  { code: 'J2', name: 'Denbighshire' },
  { code: 'J3', name: 'Derbyshire' },
  { code: 'J4', name: 'Derry/Londonderry' },
  { code: 'J5', name: 'Devon' },
  { code: 'J6', name: 'Dorset' },
  { code: 'J7', name: 'Down' },
  { code: 'J8', name: 'Dumfries and Galloway' },
  { code: 'J9', name: 'Dunbartonshire' },
  { code: 'JA', name: 'Dundee' },
  { code: 'JB', name: 'Durham' },
  { code: 'JC', name: 'Dyfed' },
  { code: 'JD', name: 'East Ayrshire' },
  { code: 'JE', name: 'East Dunbartonshire' },
  { code: 'JF', name: 'East Lothian' },
  { code: 'JG', name: 'East Renfrewshire' },
  { code: 'JH', name: 'East Riding Yorkshire' },
  { code: 'JI', name: 'East Sussex' },
  { code: 'JJ', name: 'Edinburgh' },
  { code: 'JK', name: 'England' },
  { code: 'JL', name: 'Essex' },
  { code: 'JM', name: 'Falkirk' },
  { code: 'JN', name: 'Fermanagh' },
  { code: 'JO', name: 'Fife' },
  { code: 'JP', name: 'Flintshire' },
  { code: 'JQ', name: 'Glasgow' },
  { code: 'JR', name: 'Gloucestershire' },
  { code: 'JS', name: 'Greater London' },
  { code: 'JT', name: 'Greater Manchester' },
  { code: 'JU', name: 'Gwent' },
  { code: 'JV', name: 'Gwynedd' },
  { code: 'JW', name: 'Hampshire' },
  { code: 'JX', name: 'Hartlepool' },
  { code: 'HAW', name: 'Hereford and Worcester' },
  { code: 'JY', name: 'Hertfordshire' },
  { code: 'JZ', name: 'Highlands' },
  { code: 'K0', name: 'Inverclyde' },
  { code: 'K1', name: 'Inverness-Shire' },
  { code: 'K2', name: 'Isle of Man' },
  { code: 'K3', name: 'Isle of Wight' },
  { code: 'K4', name: 'Kent' },
  { code: 'K5', name: 'Kincardinshire' },
  { code: 'K6', name: 'Kingston Upon Hull' },
  { code: 'K7', name: 'Kinross-Shire' },
  { code: 'K8', name: 'Kirklees' },
  { code: 'K9', name: 'Lanarkshire' },
  { code: 'KA', name: 'Lancashire' },
  { code: 'KB', name: 'Leicestershire' },
  { code: 'KC', name: 'Lincolnshire' },
  { code: 'KD', name: 'Londonderry' },
  { code: 'KE', name: 'Merseyside' },
  { code: 'KF', name: 'Merthyr Tydfil' },
  { code: 'KG', name: 'Mid Glamorgan' },
  { code: 'KI', name: 'Mid Lothian' },
  { code: 'KH', name: 'Middlesex' },
  { code: 'KJ', name: 'Monmouthshire' },
  { code: 'KK', name: 'Moray' },
  { code: 'KL', name: 'Neath \u0026 Port Talbot' },
  { code: 'KM', name: 'Newport' },
  { code: 'KN', name: 'Norfolk' },
  { code: 'KP', name: 'North Ayrshire' },
  { code: 'KQ', name: 'North East Lincolnshire' },
  { code: 'KR', name: 'North Lanarkshire' },
  { code: 'KT', name: 'North Lincolnshire' },
  { code: 'KU', name: 'North Somerset' },
  { code: 'KV', name: 'North Yorkshire' },
  { code: 'KO', name: 'Northamptonshire' },
  { code: 'KW', name: 'Northern Ireland' },
  { code: 'KX', name: 'Northumberland' },
  { code: 'KZ', name: 'Nottinghamshire' },
  { code: 'L0', name: 'Orkney and Shetland Isles' },
  { code: 'L1', name: 'Oxfordshire' },
  { code: 'L2', name: 'Pembrokeshire' },
  { code: 'L3', name: 'Perth and Kinross' },
  { code: 'L4', name: 'Powys' },
  { code: 'L5', name: 'Redcar and Cleveland' },
  { code: 'L6', name: 'Renfrewshire' },
  { code: 'L7', name: 'Rhonda Cynon Taff' },
  { code: 'L8', name: 'Rutland' },
  { code: 'L9', name: 'Scottish Borders' },
  { code: 'LB', name: 'Shetland' },
  { code: 'LC', name: 'Shropshire' },
  { code: 'LD', name: 'Somerset' },
  { code: 'LE', name: 'South Ayrshire' },
  { code: 'LF', name: 'South Glamorgan' },
  { code: 'LG', name: 'South Gloucesteshire' },
  { code: 'LH', name: 'South Lanarkshire' },
  { code: 'LI', name: 'South Yorkshire' },
  { code: 'LJ', name: 'Staffordshire' },
  { code: 'LK', name: 'Stirling' },
  { code: 'LL', name: 'Stockton On Tees' },
  { code: 'LM', name: 'Suffolk' },
  { code: 'LN', name: 'Surrey' },
  { code: 'LO', name: 'Swansea' },
  { code: 'LP', name: 'Torfaen' },
  { code: 'LQ', name: 'Tyne and Wear' },
  { code: 'LR', name: 'Tyrone' },
  { code: 'LS', name: 'Vale Of Glamorgan' },
  { code: 'LT', name: 'Wales' },
  { code: 'LU', name: 'Warwickshire' },
  { code: 'LV', name: 'West Berkshire' },
  { code: 'LW', name: 'West Dunbartonshire' },
  { code: 'LX', name: 'West Glamorgan' },
  { code: 'LY', name: 'West Lothian' },
  { code: 'LZ', name: 'West Midlands' },
  { code: 'M0', name: 'West Sussex' },
  { code: 'M1', name: 'West Yorkshire' },
  { code: 'M2', name: 'Western Isles' },
  { code: 'M3', name: 'Wiltshire' },
  { code: 'M4', name: 'Wirral' },
  { code: 'M5', name: 'Worcestershire' },
  { code: 'M6', name: 'Wrexham' },
  { code: 'M7', name: 'York' },
];
