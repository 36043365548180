import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { get } from 'lodash';
import {
  ActionText,
  ActionWrapper,
  DocInfoText,
  DocInfoTextWrapper,
  Document,
  DocumentsUploader,
  FieldWrapper,
  StatusImage,
  UploaderImageWrapper,
} from './DocumentFieldStyles';
import { i18n } from '../../../../../constants/i18n';
import verifyDocImage from '../../../../../assets/images/verifyDoc.png';
import emptyDocImage from '../../../../../assets/images/emptyDoc.png';
import removeDocImage from '../../../../../assets/images/remove.png';
import attachDocImage from '../../../../../assets/images/attach.png';
import successDocImage from '../../../../../assets/images/successDoc.png';
import rejectDocImage from '../../../../../assets/images/rejectDoc.png';

const DocumentField = ({ saveDocuments, removeDocument, doc }) => {
  const [document, setDocument] = useState('');
  const [formattedDocument, setFormattedDocument] = useState(null);
  const type = doc.documentType;
  const checkIsDoc = get(document, 'title', null);
  const handleChange = ({ target }) => {
    const file = target.files[0];
    if (file) {
      setDocument(file);
      const formData = new FormData();
      formData.append('file', file);
      setFormattedDocument(formData);
      // eslint-disable-next-line no-param-reassign
      target.value = '';
    }
  };
  useEffect(() => {
    if (formattedDocument) {
      saveDocuments({ file: formattedDocument, type });
    }
  }, [formattedDocument]);
  useEffect(() => {
    setDocument(doc);
  }, [doc]);

  return (
    <FieldWrapper>
      <DocumentsUploader
        type="file"
        onChange={handleChange}
        id={type}
        accept=".pdf, .jpg, .jpeg, .png, .txt, .doc, .docx, .odt"
      />
      <Document bgColor={checkIsDoc ? 'white' : null}>
        <UploaderImageWrapper>
          <div>
            {!checkIsDoc && ( // attach doc
              <>
                <StatusImage src={emptyDocImage} alt="attach file" />
                <h3>{type && type}</h3>
              </>
            )}
            {doc.isValid === true && ( //accepted doc
              <>
                <StatusImage src={successDocImage} alt="file accepted" />
                <DocInfoTextWrapper>
                  <h5>{type && type}</h5>
                  <DocInfoText color="#2FDD7F">
                    {i18n.accepted}
                    <span>{get(document, 'title', null)}</span>
                  </DocInfoText>
                </DocInfoTextWrapper>
              </>
            )}{' '}
            {doc.isValid === false && ( //rejected doc
              <>
                <StatusImage src={rejectDocImage} alt="file rejected" />
                <DocInfoTextWrapper>
                  <h5>{type && type}</h5>
                  <DocInfoText color="#FB5159">
                    {i18n.rejected}
                    <span>{get(document, 'title', null)}</span>
                  </DocInfoText>
                </DocInfoTextWrapper>
              </>
            )}
            {checkIsDoc &&
            doc.isValid === null && ( // verifying doc
                <>
                  <StatusImage src={verifyDocImage} alt="file verifying" />
                  <DocInfoTextWrapper>
                    <h5>{type && type}</h5>
                    <DocInfoText>
                      {i18n.verifying}
                      <span>{get(document, 'title', null)}</span>
                    </DocInfoText>
                  </DocInfoTextWrapper>
                </>
              )}
          </div>
          {!checkIsDoc ? (
            <ActionWrapper htmlFor={type}>
              <img src={attachDocImage} alt="attach file" />
              <ActionText>{i18n.attach}</ActionText>
            </ActionWrapper>
          ) : (
            <>
              {!doc.isValid === true && (
                <ActionWrapper onClick={() => removeDocument(doc.id)}>
                  <img src={removeDocImage} alt="file verifying" />
                  <ActionText color="#6B7F9C">{i18n.delete}</ActionText>
                </ActionWrapper>
              )}
            </>
          )}
        </UploaderImageWrapper>
      </Document>
    </FieldWrapper>
  );
};

DocumentField.propTypes = {
  saveDocuments: func.isRequired,
  removeDocument: func.isRequired,
  doc: object.isRequired,
};

export default DocumentField;
