import React, { useState } from 'react';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import EditProfileForm from '../../../components/Dashboard/EditProfileForm/EditProfileForm';
import { actions } from '../../../redux/authorization/authorization';
import { actions as actionsDashboard } from '../../../redux/dashboard/dashboard';
import { ArrowBackImage, EditProfileWrapper, FormAndNavigationContainer } from './EditProfileStyles';
import Navigation from '../../../components/Dashboard/Navigation/Navigation';
import { i18n } from '../../../constants/i18n';
import { selectDocuments, selectTherapistData } from '../../../selectors/authorizationSelector';
import ArrowBackIcon from '../../../assets/images/arrow-back.svg';
import EditDocumentsForm from '../../../components/Dashboard/EditDocumentsForm/EditDocumentsForm';
import ChangePassword from '../../../components/Dashboard/ChangePasswordForm/ChangePasswordForm';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'initial',
    fontSize: '30px',
    marginBottom: '20px',
  },
}));

const EditProfile = ({
  therapistData,
  setAvatar,
  uploadDocument,
  documents,
  updateTherapist,
  changePassword,
  getDocuments,
  removeDocument,
  ...rest
}) => {
  const classes = useStyles();
  const [stepCount, setStepCount] = useState(1);
  if (!therapistData) {
    return null;
  }
  return (
    <EditProfileWrapper>
      <Link to="/dashboard">
        <Button className={classes.button}>
          <ArrowBackImage>
            <img src={ArrowBackIcon} alt="arrow back" />
          </ArrowBackImage>{' '}
          {i18n.editYourProfile}
        </Button>
      </Link>
      <FormAndNavigationContainer>
        {stepCount === 1 && (
          <EditProfileForm
            updateTherapist={updateTherapist}
            therapistData={therapistData}
            setAvatar={setAvatar}
            initialValues={therapistData}
          />
        )}
        {stepCount === 2 && (
          <EditDocumentsForm
            getDocuments={getDocuments}
            therapistData={therapistData}
            saveDocuments={uploadDocument}
            documents={documents}
            removeDocument={removeDocument}
          />
        )}
        {stepCount === 3 && <ChangePassword changePassword={changePassword} />}
        <Navigation stepCount={stepCount} setStepCount={setStepCount} {...rest} />
      </FormAndNavigationContainer>
    </EditProfileWrapper>
  );
};

EditProfile.propTypes = {
  setAvatar: func.isRequired,
  therapistData: object,
  uploadDocument: func.isRequired,
  updateTherapist: func.isRequired,
  changePassword: func.isRequired,
  documents: array,
  getDocuments: func.isRequired,
  removeDocument: func.isRequired,
};
EditProfile.defaultProps = {
  therapistData: null,
  documents: null,
};

const mapStateToProps = createStructuredSelector({
  therapistData: (state) => selectTherapistData(state),
  documents: (state) => selectDocuments(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAvatar: (file) => dispatch(actions.setAvatar(file)),
  updateTherapist: (data) => dispatch(actions.updateTherapist.request(data)),
  uploadDocument: (payload) => dispatch(actions.sendToUploadDocuments.request(payload)),
  changePassword: (payload) => dispatch(actionsDashboard.changePassword.request(payload)),
  getDocuments: () => dispatch(actions.getDocuments.request()),
  removeDocument: (payload) => dispatch(actions.removeDocument.request(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
