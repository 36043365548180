import { createReducer, createActionCreator } from '../helpers/reduxHelper';

export const NAME = 'error';

const initialState = {
  message: null,
};

const setError = (state, { message }) => {
  return { ...state, message };
};

export const types = {
  SET_ERROR: `${NAME}/SET_ERROR`,
};

export const actions = {
  setError: createActionCreator(types.SET_ERROR),
};

export const handlers = {
  [types.SET_ERROR]: setError,
};

export default createReducer(initialState, handlers);
