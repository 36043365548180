import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';
import { createStructuredSelector } from 'reselect';
import { selectErrorMessage } from '../../selectors/errorsSelector';
import { selectSuccessMessage } from '../../selectors/dashboardSelector';

const HandleErrors = ({ message, successMessage }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (message) {
      const key = enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  }, [message, enqueueSnackbar]);

  useEffect(() => {
    if (successMessage) {
      const key = enqueueSnackbar(successMessage, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  }, [successMessage, enqueueSnackbar]);

  return null;
};
HandleErrors.propTypes = {
  message: PropTypes.string,
  successMessage: PropTypes.string,
};
const mapStateToProps = createStructuredSelector({
  message: (state) => selectErrorMessage(state),
  successMessage: (state) => selectSuccessMessage(state),
});
export default connect(mapStateToProps, null)(HandleErrors);
