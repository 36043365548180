import styled from 'styled-components';

export const LeftBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 25px 20px;
  width: 80px;
  align-items: center;
  position: relative;
  height: 100vh;
  a {
    position: fixed;
    top: 26px;
  }
`;
export const Logo = styled.img`
  display: flex;
  justify-content: space-between;
  height: 50px;
`;
export const LogOut = styled.button`
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 28px;
  color: #000000;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 25px;
`;
