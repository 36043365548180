import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import rootSaga from '../sagas/rootSaga';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger);
// }

const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

export default store;
