import React from 'react';
import { createPortal } from 'react-dom';
import { SpinnerContainer, SpinnerOverlay } from './SpinnerStyles';

const spinnerRoot = document.getElementById('spinner');

const Spinner = () =>
  createPortal(
    <SpinnerOverlay>
      <SpinnerContainer />
    </SpinnerOverlay>,
    spinnerRoot,
  );

export default Spinner;
