import { createActionCreator, createReducer } from '../helpers/reduxHelper';

export const NAME = 'dialog';

const initialState = {
  signInDialogState: false,
  signUpDialogState: false,
  restoreDialogState: false,
  showRestorePassword: false,
  showCheckYourMail: false,
  signUpStepTwoDialogState: false,
  waitForApproveState: false,
  availabilityDialogState: false,
  editAndDeleteDialogState: false,
  confirmDialogLogoutState: false,
  confirmDialogAppointmentsState: false,
  appointmentNoteDialogState: false,
  contactUsDialogState: false,
  contactUsSuccessDialogState: false,
};

export const types = {
  SIGN_IN_TOGGLE_DIALOG: `${NAME}/SIGN_IN_TOGGLE_DIALOG`,
  SIGN_UP_TOGGLE_DIALOG: `${NAME}/SIGN_UP_TOGGLE_DIALOG`,
  RESTORE_TOGGLE_DIALOG: `${NAME}/RESTORE_TOGGLE_DIALOG`,
  SIGN_UP_STEP_TWO_TOGGLE_DIALOG: `${NAME}/SIGN_UP_STEP_TWO_TOGGLE_DIALOG`,
  TOGGLE_RESTORE_VIEW: `${NAME}/TOGGLE_RESTORE_VIEW`,
  TOGGLE_CHECK_YOUR_MAIL: `${NAME}/TOGGLE_CHECK_YOUR_MAIL`,
  WAIT_FOR_APPROVE_DIALOG: `${NAME}/WAIT_FOR_APPROVE_DIALOG`,
  TOGGLE_AVAILABILITY_DIALOG: `${NAME}/AVAILABILITY_DIALOG`,
  EDIT_AND_DELETE_AVAILABILITY_DIALOG: `${NAME}/EDIT_AND_DELETE_AVAILABILITY_DIALOG`,
  CONFIRM_DIALOG_APPOINTMENTS: `${NAME}/CONFIRM_DIALOG_APPOINTMENTS`,
  CONFIRM_DIALOG_LOGOUT: `${NAME}/CONFIRM_DIALOG_LOGOUT`,
  APPOINTMENT_NOTE_DIALOG: `${NAME}/APPOINTMENT_NOTE_DIALOG`,
  CONTACT_US_DIALOG: `${NAME}/CONTACT_US_DIALOG`,
  CONTACT_US_SUCCESS_DIALOG: `${NAME}/CONTACT_US_SUCCESS_DIALOG`,
};

export const actions = {
  signInToggleDialog: createActionCreator(types.SIGN_IN_TOGGLE_DIALOG),
  signUpToggleDialog: createActionCreator(types.SIGN_UP_TOGGLE_DIALOG),
  restoreToggleDialog: createActionCreator(types.RESTORE_TOGGLE_DIALOG),
  signUpStepTwoDialogState: createActionCreator(types.SIGN_UP_STEP_TWO_TOGGLE_DIALOG),
  toggleRestoreView: createActionCreator(types.TOGGLE_RESTORE_VIEW),
  toggleCheckYourMail: createActionCreator(types.TOGGLE_CHECK_YOUR_MAIL),
  toggleWaitForApprove: createActionCreator(types.WAIT_FOR_APPROVE_DIALOG),
  toggleAvailabilityDialogState: createActionCreator(types.TOGGLE_AVAILABILITY_DIALOG),
  toggleEditAndDeleteDialogState: createActionCreator(types.EDIT_AND_DELETE_AVAILABILITY_DIALOG),
  toggleConfirmDialogAppointmentsState: createActionCreator(types.CONFIRM_DIALOG_APPOINTMENTS),
  toggleConfirmDialogLogoutState: createActionCreator(types.CONFIRM_DIALOG_LOGOUT),
  toggleAppointmentNoteDialog: createActionCreator(types.APPOINTMENT_NOTE_DIALOG),
  toggleContactUsDialog: createActionCreator(types.CONTACT_US_DIALOG),
  toggleContactUsSuccessDialog: createActionCreator(types.CONTACT_US_SUCCESS_DIALOG),
};

export const handlers = {
  [types.SIGN_IN_TOGGLE_DIALOG]: (state, { payload }) => ({
    ...state,
    signInDialogState: !payload,
    showRestorePassword: false,
    signUpDialogState: false,
    restoreDialogState: false,
    contactUsDialogState: false,
  }),
  [types.CONTACT_US_DIALOG]: (state, { payload }) => ({
    ...state,
    contactUsDialogState: !payload,
    signInDialogState: false,
  }),
  [types.CONTACT_US_SUCCESS_DIALOG]: (state, { payload }) => ({
    ...state,
    contactUsSuccessDialogState: !payload,
    contactUsDialogState: false,
  }),
  [types.SIGN_UP_TOGGLE_DIALOG]: (state, { payload }) => ({
    ...state,
    signUpDialogState: !payload,
    showCheckYourMail: false,
    signInDialogState: false,
  }),
  [types.RESTORE_TOGGLE_DIALOG]: (state, { payload }) => ({
    ...state,
    restoreDialogState: !payload,
  }),
  [types.SIGN_UP_STEP_TWO_TOGGLE_DIALOG]: (state, { payload }) => ({
    ...state,
    signUpStepTwoDialogState: !payload,
    waitForApproveState: false,
  }),
  [types.TOGGLE_RESTORE_VIEW]: (state, { payload }) => ({ ...state, showRestorePassword: !payload }),
  [types.TOGGLE_CHECK_YOUR_MAIL]: (state, { payload }) => ({
    ...state,
    showCheckYourMail: !payload,
    signInDialogState: false,
  }),
  [types.WAIT_FOR_APPROVE_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      waitForApproveState: !payload,
      signUpStepTwoDialogState: false,
    };
  },
  [types.TOGGLE_AVAILABILITY_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      availabilityDialogState: !payload,
    };
  },
  [types.EDIT_AND_DELETE_AVAILABILITY_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      editAndDeleteDialogState: !payload,
    };
  },
  [types.CONFIRM_DIALOG_APPOINTMENTS]: (state, { payload }) => {
    return {
      ...state,
      confirmDialogAppointmentsState: !payload,
    };
  },
  [types.CONFIRM_DIALOG_LOGOUT]: (state, { payload }) => {
    return {
      ...state,
      confirmDialogLogoutState: !payload,
    };
  },
  [types.APPOINTMENT_NOTE_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      appointmentNoteDialogState: !payload,
    };
  },
};

export default createReducer(initialState, handlers);
