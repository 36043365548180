import React, { useEffect } from 'react';
import ChipInput from 'material-ui-chip-input';
import { bool, object, shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { i18n } from '../../constants/i18n';

const useStyles = makeStyles(() => ({
  root: {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

const InputChip = ({
  label,
  input: { value, onChange, onBlur, ...inputRest },
  meta: { touched, error },
  ...custom
}) => {
  const classes = useStyles();
  const handleDelete = (chip, i) => {
    const tags = [...value];
    tags.splice(i, 1);
    onChange(tags);
  };
  useEffect(() => {
    onChange([...value]);
  }, []);
  const handleAdd = (e) => {
    onChange([...value, e]);
  };

  return (
    <Tooltip title={i18n.useEnterKey} placement="top">
      <ChipInput
        value={value || null}
        onDelete={handleDelete}
        onAdd={handleAdd}
        variant="outlined"
        label={label || custom.placeholder}
        placeholder={label}
        error={touched && !!error}
        helperText={touched && error}
        className={classes.root}
        onBlur={() => {
          onBlur([...value]);
        }}
        {...inputRest}
        {...custom}
      />
    </Tooltip>
  );
};

InputChip.propTypes = {
  input: object.isRequired,
  label: string,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

InputChip.defaultProps = {
  label: null,
};

export default InputChip;
