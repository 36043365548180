import {
  createReducer,
  requestHelpers,
  createRequestTypes,
  createRequestAction,
  createActionCreator,
} from '../helpers/reduxHelper';

export const NAME = 'dashboard';

const initialState = {
  appointments: null,
  error: null,
  isFetching: false,
  eventDate: null,
  calendarData: null,
  selectedWeek: null,
  roomData: null,
  canceledAppointment: null,
  receiptsData: null,
  pdfReceiptFile: null,
  currentNote: null,
  currentNoteFromApi: '',
  successMessage: null,
};

const successMessage = 'Notes updated successfully';

const startFetching = (state) => ({ ...state, isFetching: true, successMessage: null });
const requestSuccess = (state, { payload }) => ({ ...state, isFetching: false });
const requestFailure = (state, { payload }) => {
  return { ...state, error: payload, isFetching: false };
};
const setAppointments = (state, { payload }) => ({ ...state, appointments: payload, isFetching: false });
const setEventDate = (state, { payload }) => ({ ...state, eventDate: payload });
const setCurrentNote = (state, { payload }) => ({ ...state, currentNote: payload });
const setCalendarData = (state, { payload }) => ({ ...state, calendarData: payload, isFetching: false });
const setSelectedWeek = (state, { payload }) => ({ ...state, selectedWeek: payload });
const getReceipts = (state, { payload }) => ({ ...state, receiptsData: payload });
const getReceiptPdfFile = (state, { payload }) => ({ ...state, pdfReceiptFile: payload });
const connectToRoomSuccess = (state, { payload }) => ({
  ...state,
  roomData: payload,
  isFetching: false,
});
const disconnectFromRoom = (state) => {
  return ({
    ...state,
    roomData: null,
    isFetching: false,
  })
}
const getCurrentNote = (state, { payload }) => ({
  ...state,
  currentNoteFromApi: payload,
  isFetching: false,
});
const cancelAppointmentSuccess = (state, { payload }) => ({
  ...state,
  canceledAppointment: payload,
  isFetching: false,
});

const updateAppointmentNote = (state) => ({
  ...state,
  successMessage,
  isFetching: false,
});

export const types = {
  GET_APPOINTMENTS: createRequestTypes(`${NAME}/SET_APPOINTMENTS`),
  SET_EVENT_DATE: `${NAME}/SET_EVENT_DATE`,
  SET_SELECTED_WEEK: `${NAME}/SET_SELECTED_WEEK`,
  SET_CURRENT_NOTE: `${NAME}/SET_CURRENT_NOTE`,
  DISCONNECT_FROM_ROOM: `${NAME}/DISCONNECT_FROM_ROOM`,
  GET_CALENDAR_DATA: createRequestTypes(`${NAME}/SET_CALENDAR_DATA`),
  ADD_CALENDAR_DATA: createRequestTypes(`${NAME}/SET_CALENDAR_DATA`),
  ADD_CALENDAR_CUSTOM_AVAILABILITY: createRequestTypes(`${NAME}/ADD_CALENDAR_CUSTOM_AVAILABILITY`),
  ADD_CALENDAR_RECURRENT_AVAILABILITY: createRequestTypes(`${NAME}/ADD_CALENDAR_RECURRENT_AVAILABILITY`),
  CONNECT_TO_ROOM: createRequestTypes(`${NAME}/CONNECT_TO_ROOM`),
  CANCEL_APPOINTMENT: createRequestTypes(`${NAME}/CANCEL_APPOINTMENT`),
  DELETE_CUSTOM_AVAILABILITY: createRequestTypes(`${NAME}/DELETE_CUSTOM_AVAILABILITY`),
  UPDATE_APPOINTMENT_NOTE: createRequestTypes(`${NAME}/UPDATE_APPOINTMENT_NOTE`),
  GET_RECEIPTS: createRequestTypes(`${NAME}/GET_RECEIPTS`),
  GET_RECEIPT_PDF_FILE: createRequestTypes(`${NAME}/GET_RECEIPT_PDF_FILE`),
  CHANGE_PASSWORD: createRequestTypes(`${NAME}/CHANGE_PASSWORD`),
  GET_CURRENT_NOTE: createRequestTypes(`${NAME}/GET_CURRENT_NOTE`),
};

export const actions = {
  getAppointments: createRequestAction(types.GET_APPOINTMENTS),
  setEventDate: createActionCreator(types.SET_EVENT_DATE),
  setCurrentNote: createActionCreator(types.SET_CURRENT_NOTE),
  setSelectedWeek: createActionCreator(types.SET_SELECTED_WEEK),
  disconnectFromRoom: createActionCreator(types.DISCONNECT_FROM_ROOM),
  getCalendarData: createRequestAction(types.GET_CALENDAR_DATA),
  addCalendarCustomAvailability: createRequestAction(types.ADD_CALENDAR_CUSTOM_AVAILABILITY),
  addCalendarRecurrentAvailability: createRequestAction(types.ADD_CALENDAR_RECURRENT_AVAILABILITY),
  connectToRoom: createRequestAction(types.CONNECT_TO_ROOM),
  cancelAppointment: createRequestAction(types.CANCEL_APPOINTMENT),
  deleteCustomAvailability: createRequestAction(types.DELETE_CUSTOM_AVAILABILITY),
  updateAppointmentNote: createRequestAction(types.UPDATE_APPOINTMENT_NOTE),
  getReceipts: createRequestAction(types.GET_RECEIPTS),
  getReceiptPdfFile: createRequestAction(types.GET_RECEIPT_PDF_FILE),
  changePassword: createRequestAction(types.CHANGE_PASSWORD),
  getCurrentNote: createRequestAction(types.GET_CURRENT_NOTE),
};

export const handlers = {
  ...requestHelpers(types.GET_APPOINTMENTS, {
    startFetching,
    requestSuccess: setAppointments,
    requestFailure,
  }),
  ...requestHelpers(types.GET_CURRENT_NOTE, {
    startFetching,
    requestSuccess: getCurrentNote,
    requestFailure,
  }),
  ...requestHelpers(types.ADD_CALENDAR_CUSTOM_AVAILABILITY, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.ADD_CALENDAR_RECURRENT_AVAILABILITY, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.GET_CALENDAR_DATA, {
    startFetching,
    requestSuccess: setCalendarData,
    requestFailure,
  }),
  ...requestHelpers(types.CONNECT_TO_ROOM, {
    startFetching,
    requestSuccess: connectToRoomSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.CANCEL_APPOINTMENT, {
    startFetching,
    requestSuccess: cancelAppointmentSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.DELETE_CUSTOM_AVAILABILITY, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.UPDATE_APPOINTMENT_NOTE, {
    startFetching,
    requestSuccess: updateAppointmentNote,
    requestFailure,
  }),
  ...requestHelpers(types.GET_RECEIPTS, {
    startFetching,
    requestSuccess: getReceipts,
    requestFailure,
  }),
  ...requestHelpers(types.GET_RECEIPT_PDF_FILE, {
    startFetching,
    requestSuccess: getReceiptPdfFile,
    requestFailure,
  }),
  ...requestHelpers(types.CHANGE_PASSWORD, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  [types.SET_EVENT_DATE]: setEventDate,
  [types.SET_SELECTED_WEEK]: setSelectedWeek,
  [types.SET_CURRENT_NOTE]: setCurrentNote,
  [types.DISCONNECT_FROM_ROOM]: disconnectFromRoom,
};

export default createReducer(initialState, handlers);
