import styled from 'styled-components';

export const DocumentsProfileFormWrapper = styled.div`
  background: white;
  width: 100%;
  padding: 24px 24px;
  form {
    display: flex;
    flex-direction: column;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
