import React from 'react';
import { object, string, bool, array, func, shape, element } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { actions } from '../../redux/authorization/authorization';
import { selectPostCodes } from '../../selectors/authorizationSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '527px',
    borderWidth: 2,
    borderColor: 'red',
    color: '#1C1F24',
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
    },
  },
  formControl: {
    flex: '0 45%',
    display: 'flex',
    height: '115px',
    margin: '0 16px',
  },
  errorMsg: {
    color: '#f44336',
    textAlign: 'left',
    maxWidth: '524px',
    width: '100%',
  },
}));

const AutoCompleteSelect = ({
  label,
  children,
  input,
  meta: { touched, error },
  postCodes,
  getPostCodes,
  ...custom
}) => {
  const classes = useStyles();
  const debounceSearch = debounce(getPostCodes, 500);
  return (
    <FormControl variant="outlined" className={custom.className || classes.formControl}>
      <Autocomplete
        {...input}
        label={label}
        onChange={(event, value) => input.onChange(value)}
        options={postCodes || []}
        placeholder={label}
        error={touched && !!error}
        helperText={touched && error}
        getOptionLabel={(option) => {
          return postCodes.length ? get(option, 'label', option) : '';
        }}
        onInputChange={(event, newInputValue) => {
          debounceSearch(newInputValue);
        }}
        {...custom}
        className={classes.root}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
      />
      <FormHelperText className={classes.errorMsg}>{touched && error}</FormHelperText>
    </FormControl>
  );
};

AutoCompleteSelect.propTypes = {
  input: object.isRequired,
  label: string,
  children: element.isRequired,
  postCodes: array,
  getPostCodes: func.isRequired,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

AutoCompleteSelect.defaultProps = {
  label: null,
  postCodes: [],
};

const mapStateToProps = createStructuredSelector({
  postCodes: (state) => selectPostCodes(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPostCodes: (value) => dispatch(actions.getPostCodes.request(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteSelect);
