import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { object } from 'prop-types';
import Header from '../../components/Header/Header';

const AuthPage = lazy(() => import('../../pages/AuthPage/AuthPageContainer'));
const RestorePassword = lazy(() => import('../../components/RestorePassword/RestorePassword'));

const AuthRouter = ({ match, location: { pathname } }) => (
  <>
    {!pathname.includes('dashboard') && <Header />}
    <Switch>
      <Route path={`${match.path}`} component={AuthPage} />
      <Route exact path={`${match.path}/token`} component={RestorePassword} />
      <Route exact path={`${match.path}/activate-therapist`} component={AuthPage} />
      <Route exact path={`${match.path}/reset/finish`} component={AuthPage} />
    </Switch>
  </>
);

AuthRouter.propTypes = {
  match: object.isRequired,
  location: object.isRequired,
};
export default AuthRouter;
