import { put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { types } from '../../redux/errors/errors';
import { unauthorized } from '../../constants/errors';

export default function* watchAndLog() {
  while (true) {
    const action = yield take('*');
    if (action.type.includes('FAILURE')) {
      if (action.payload === unauthorized) {
        yield put(push('/account'));
      }
      yield put({ type: types.SET_ERROR, message: action.payload });
      yield put({ type: types.SET_ERROR, message: null });
    }
  }
}
