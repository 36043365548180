import styled, { css } from 'styled-components';

const buttonStyles = css`
  background-color: ${({ bgColor }) => bgColor || '#4f2ad7'};
  color: ${({ color }) => color || 'white'};
  border: ${({ border }) => border || 'none'};
  &:hover {
    background-color: ${({ bgColorHover }) => bgColorHover || 'white'};
    color: black;
    border: ${({ borderHover }) => borderHover || '1px solid black'};
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  border: 1px solid black;
  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const getButtonStyles = ({ inverted }) => (inverted ? invertedButtonStyles : buttonStyles);

export const CustomButtonContainer = styled.button`
  width: ${(props) => (props.width ? props.width : '327px')};
  align-items: center;
  border-radius: 100px;
  height: 48px;
  letter-spacing: 0.5px;
  line-height: 25px;
  padding: 0 28px 0 28px;
  font-size: 16px;
  border-radius: 30px;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  margin-top: 25px;
  justify-content: center;
  ${getButtonStyles}
  &:disabled {
    background-color: #b9babd;
  }
`;
