import styled from 'styled-components';

export const EditProfileWrapper = styled.div`
  width: 100%;
  background: rgba(243, 244, 255);
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 100px 0 0 90px;
  overflow: auto;
  height: 100vh;
  a {
    text-decoration: none;
  }
`;
export const FormAndNavigationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const FormTitle = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #7a8697;
  margin-bottom: 16px;
`;
export const ArrowBackImage = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;
