import styled from 'styled-components';

export const Document = styled.div`
  background: ${({ bgColor }) => bgColor || 'rgba(174, 176, 182, 0.15)'};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
`;

export const DocumentsUploader = styled.input`
  display: none;
`;
export const ActionText = styled.span`
  color: ${({ color }) => color || '#5B3FBB'};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;
export const UploaderImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 12px 16px;
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #6b7f9c;
    margin-left: 16px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;
export const ActionWrapper = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  img {
    height: 20px;
    margin-right: 7px;
  }
`;
export const DocumentWrapper = styled.div`
  form {
    padding 0 0 16px 0;
    display: flex;
    align-items: center;
  }
`;
export const StatusImage = styled.img`
  width: 40px;
  height: 40px;
`;
export const FileYouAttach = styled.span`
  width: 100%;
  color: #aeb0b6;
  font-weight: normal;
  margin: 24px 0 16px 0;
`;
