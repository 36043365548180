export const documentsEnumTypes = [
  { documentType: 'CV' },
  { documentType: 'REFERENCE_1' },
  { documentType: 'REFERENCE_2' },
  { documentType: 'PROFESSIONAL_INDEMNITY_PROOF_OF_MEMBERSHIP' },
  { documentType: 'RIGHT_TO_WORK' },
  { documentType: 'ID' },
  { documentType: 'PROOF_OF_ADDRESS' },
  { documentType: 'REGULATORY_BODY_MEMBERSHIP' },
  { documentType: 'ENHANCED_DBS_CERTIFICATE' },
  { documentType: 'SIGNED_DBS_CONSENT_FORM' },
  { documentType: 'SIGNED_AGREEMENT' },
  { documentType: 'INTERVIEW' },
  { documentType: 'MARPE_TUTORIAL' },
  { documentType: 'OTHER' },
];
