import React, { useEffect, useState } from 'react';
import { DatePicker as DatePickerMaterial, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { bool, object, shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ukLocale from 'date-fns/locale/en-GB';

const useStyles = makeStyles(() => ({
  root: {
    width: '327px',
    color: '#1C1F24',
    margin: '10px 0',
    borderWidth: 2,
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
      color: 'red',
    },
  },
}));

const DatePicker = ({ input, label, meta: { touched, error }, ...custom }) => {
  const classes = useStyles();
  const now = new Date();
  const maxDate = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
  const [selectedDate, setValue] = useState(() => {
    return input.value ? new Date(input.value) : null;
  });
  useEffect(() => {
    if (selectedDate) {
      const date = moment(selectedDate).format('yyyy-MM-DD');
      input.onChange(date);
    }

    if (!selectedDate && input.value) {
      setValue(new Date(input.value));
    }
  }, [selectedDate, setValue, input.value]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ukLocale}>
      <DatePickerMaterial
        {...input}
        label={custom.placeholder}
        placeholder={custom.placeholder}
        onChange={setValue}
        inputVariant="outlined"
        value={selectedDate}
        variant="outlined"
        maxDate={maxDate}
        className={custom.fullWidth ? '' : classes.root}
        format="dd-MM-yyyy"
        helperText={touched && error && error}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  );
};
DatePicker.propTypes = {
  input: object.isRequired,
  label: string,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

DatePicker.defaultProps = {
  label: null,
};
export default DatePicker;
