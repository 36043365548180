import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import authorization, { NAME as AuthReducer } from './authorization/authorization';
import dialog, { NAME as DialogReducer } from './dialog/dialog';
import dashboard, { NAME as DashboardReducer } from './dashboard/dashboard';
import errors, { NAME as ErrorsReducer } from './errors/errors';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    [AuthReducer]: authorization,
    [DialogReducer]: dialog,
    [DashboardReducer]: dashboard,
    [ErrorsReducer]: errors,
  });

export default rootReducer;
