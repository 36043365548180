import { func, array, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Appointment,
  AppointmentInfo,
  AppointmentsRow,
  AppointmentsWrapper,
  ArrowBackImage,
  ButtonWrapper,
  ContentWrapper,
  EmptyAvatar,
  EmptyReceipts,
  ImageWrapper,
  LeftBlock,
  TotalPenny,
} from './ReceiptsPageStyles';
import Button from '../../../components/Button/Button';
import { i18n } from '../../../constants/i18n';
import { selectedReceiptPdfFile, selectedReceiptsData } from '../../../selectors/dashboardSelector';
import { actions } from '../../../redux/dashboard/dashboard';
import ArrowBackIcon from '../../../assets/images/arrow-back.svg';
import emptyReceiptImage from '../../../assets/images/emptyReceipts.png';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'initial',
    fontSize: '30px',
    marginBottom: '20px',
  },
}));

const ReceiptsPage = ({ receiptsData, getReceipts, receiptPdfFile, getReceiptPdfFile }) => {
  const classes = useStyles();
  const downloadPdf = (id) => {
    getReceiptPdfFile(id);
  };
  useEffect(() => {
    if (receiptPdfFile) {
      const blob = new Blob([receiptPdfFile], { type: 'application/pdf' });
      saveAs(blob, 'report.pdf');
    }
    getReceipts();
  }, [receiptPdfFile]);
  return (
    <AppointmentsWrapper>
      <Link to="/dashboard">
        <Button
          bgColorHover="none"
          borderHover="none"
          color="black"
          bgColor="transparent"
          textColor="black"
          className={classes.button}
        >
          <ArrowBackImage>
            <img src={ArrowBackIcon} alt="arrow back" />
          </ArrowBackImage>{' '}
          {i18n.myReceipts}
        </Button>
      </Link>
      {receiptsData && receiptsData.length ? (
        <AppointmentsRow>
          {!!receiptsData.length &&
            receiptsData.map((el) => (
              <Appointment key={el.paymentId}>
                <ContentWrapper>
                  <LeftBlock>
                    <ImageWrapper>
                      {el.clientImageUrl ? (
                        <img src={el.clientImageUrl} alt="avatar" />
                      ) : (
                        <EmptyAvatar>
                          {el.clientFirstName && el.clientFirstName[0].toUpperCase()}{' '}
                          {el.clientLastName && el.clientLastName[0].toUpperCase()}
                        </EmptyAvatar>
                      )}
                      <TotalPenny>{`£${el.penny / 100}`}</TotalPenny>
                    </ImageWrapper>
                    <AppointmentInfo>
                      <h2>
                        {el.clientFirstName} {el.clientLastName}
                      </h2>
                      <p>{moment(el.appointmentStart).format('DD MMMM')}</p>
                      <p>{`${moment(el.appointmentStart).format('HH:mm')} - ${moment(el.appointmentEnd).format(
                        'HH:mm',
                      )}`}</p>
                    </AppointmentInfo>
                  </LeftBlock>
                </ContentWrapper>
                <ButtonWrapper>
                  <p>{el.note}</p>
                  <Button
                    width="100%"
                    color="#5B3FBB"
                    bgColor="rgba(95, 62, 212, 0.05)"
                    onClick={() => downloadPdf(el.paymentId)}
                  >
                    {i18n.downloadPdf}
                  </Button>
                </ButtonWrapper>
              </Appointment>
            ))}
        </AppointmentsRow>
      ) : (
        <EmptyReceipts>
          <img src={emptyReceiptImage} alt="empty receipts" />
          <p>{i18n.emptyReceipts}</p>
        </EmptyReceipts>
      )}
    </AppointmentsWrapper>
  );
};
ReceiptsPage.propTypes = {
  getReceipts: func.isRequired,
  getReceiptPdfFile: func.isRequired,
  receiptsData: array,
  receiptPdfFile: object,
};
ReceiptsPage.defaultProps = {
  receiptsData: null,
  receiptPdfFile: null,
};

const mapStateToProps = createStructuredSelector({
  receiptsData: (state) => selectedReceiptsData(state),
  receiptPdfFile: (state) => selectedReceiptPdfFile(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReceipts: () => dispatch(actions.getReceipts.request()),
  getReceiptPdfFile: (payload) => dispatch(actions.getReceiptPdfFile.request(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptsPage);
