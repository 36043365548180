import React from 'react';
import { object, string, bool, shape, element } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '527px',
    borderWidth: 2,
    color: '#1C1F24',
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
    },
  },
  formControl: {
    width: '100%',
    maxWidth: '527px',
    minHeight: '90px',
  },
  errorMsg: {
    color: '#f44336',
    textAlign: 'left',
    maxWidth: '524px',
    width: '100%',
    marginTop: '-10px',
  },
}));

const SelectInput = ({ label, children, input, meta: { touched, error }, ...custom }) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={custom.formControlClass || classes.formControl}>
      <InputLabel htmlFor={`${label}-${input.name}`}>{label}</InputLabel>
      <Select
        id={`${label}-${input.name}`}
        label={label}
        placeholder={label}
        {...input}
        onChange={(value) => input.onChange(value)}
        className={classes.root}
        error={touched && !!error}
        helperText={touched && error}
        {...custom}
      >
        {children}
      </Select>
      <FormHelperText className={classes.errorMsg}>{touched && error}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  input: object.isRequired,
  label: string,
  children: element.isRequired,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

SelectInput.defaultProps = {
  label: null,
};

export default SelectInput;
