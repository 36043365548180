import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  div {
    display: flex;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
`;
export const Logo = styled.img`
  height: 50px;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
`;
export const NavBar = styled.div`
  padding: 12px;
  padding-top: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 50;
  justify-content: center;
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const SignInButton = styled.button`
  font-size: 18px;
  color: white;
  background: transparent;
  border: none;
  margin-right: 20px;
`;
export const SignUpButton = styled.button`
  font-size: 18px;
  color: white;
  font-weight: 600;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  background: ${(props) => (props.background ? props.background : 'white')};
  padding: 0 50px;
  height: 54px;
  margin-bottom: 10px;
  min-width: 291px;
`;
