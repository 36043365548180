import {
  createReducer,
  createRequestTypes,
  createRequestAction,
  requestHelpers,
  createActionCreator,
} from '../helpers/reduxHelper';

export const NAME = 'authorization';

const initialState = {
  token: null,
  status: false,
  error: null,
  isFetching: false,
  restoreData: null,
  restoredEmail: null,
  restorePass: null,
  userPassword: null,
  signUpEmail: null,
  userCredentials: null,
  therapistDocument: null,
  avatarImage: null,
  avatarImageNotFormatted: null,
  key: null,
  therapistData: null,
  stripeApiKey: null,
  stripeToken: null,
  documents: null,
  postCodes: null,
};

const startFetching = (state) => ({ ...state, isFetching: true });
const requestSuccess = (state, { payload }) => ({ ...state, isFetching: false, token: payload });
const requestFailure = (state, { payload }) => {
  return { ...state, error: payload, isFetching: false };
};

const signOut = (state) => ({ ...state, therapistData: null, token: null });
const restoreSentSuccessful = (state, { payload }) => ({ ...state, isFetching: false, restoreData: payload });
const activateUserSuccess = (state, { payload }) => ({ ...state, isFetching: false, userCredentials: payload });
const restoreSentFinish = (state, { payload }) => ({ ...state, isFetching: false, restorePass: payload });
const setRestoredEmail = (state, { payload }) => ({ ...state, restoredEmail: payload });
const setSignUpEmail = (state, { payload }) => ({ ...state, signUpEmail: payload.email });
const setPassword = (state, { payload }) => ({ ...state, userPassword: payload });
const setTherapistDocument = (state, { payload }) => ({ ...state, therapistDocument: payload });
const setKey = (state, { payload }) => ({ ...state, key: payload.key });
const setAvatar = (state, { payload }) => ({ ...state, avatarImage: payload });
const setAvatarNotFormatted = (state, { payload }) => ({ ...state, avatarImageNotFormatted: payload });
const getCurrentTherapist = (state, { payload }) => ({ ...state, isFetching: false, therapistData: payload });
const setStripeApiKey = (state, { payload }) => ({ ...state, isFetching: false, stripeApiKey: payload });
const setStripeToken = (state, { payload }) => ({ ...state, stripeToken: payload });
const getDocuments = (state, { payload }) => ({ ...state, isFetching: false, documents: payload });
const getPostCodes = (state, { payload }) => ({ ...state, isFetching: false, postCodes: payload });

export const types = {
  SIGN_IN: createRequestTypes(`${NAME}/SIGN_IN`),
  SIGN_UP: createRequestTypes(`${NAME}/SIGN_UP`),
  SIGN_OUT: `${NAME}/SIGN_OUT`,
  ACTIVATE_USER: createRequestTypes(`${NAME}/ACTIVATE_USER`),
  UPDATE_THERAPIST: createRequestTypes(`${NAME}/UPDATE_THERAPIST`),
  SEND_TO_RESTORE: createRequestTypes(`${NAME}/SEND_TO_RESTORE`),
  SEND_TO_RESTORE_FINISH: createRequestTypes(`${NAME}/SEND_TO_RESTORE_FINISH`),
  SEND_TO_UPLOAD: createRequestTypes(`${NAME}/SEND_TO_UPLOAD`),
  SEND_TO_UPLOAD_DOCUMENTS: createRequestTypes(`${NAME}/SEND_TO_UPLOAD_DOCUMENTS`),
  SET_RESTORED_EMAIL: `${NAME}/SET_RESTORED_EMAIL`,
  SET_PASSWORD: `${NAME}/SET_PASSWORD`,
  SET_KEY: `${NAME}/SET_KEY`,
  SET_AVATAR: `${NAME}/SET_AVATAR`,
  SET_AVATAR_NOT_FORMATTED: `${NAME}/SET_AVATAR_NOT_FORMATTED`,
  SET_THERAPIST_DOCUMENT: `${NAME}/SET_THERAPIST_DOCUMENT`,
  SET_STRIPE_TOKEN: `${NAME}/SET_STRIPE_TOKEN`,
  SET_CURRENT_THERAPIST: createRequestTypes(`${NAME}/SET_CURRENT_THERAPIST`),
  SET_STRIPE_API_KEY: createRequestTypes(`${NAME}/SET_STRIPE_API_KEY`),
  GET_DOCUMENTS: createRequestTypes(`${NAME}/GET_DOCUMENTS`),
  REMOVE_DOCUMENT: createRequestTypes(`${NAME}/REMOVE_DOCUMENT`),
  GET_POSTCODES: createRequestTypes(`${NAME}/GET_POSTCODES`),
  CONTACT_US: createRequestTypes(`${NAME}/CONTACT_US`),
};

export const actions = {
  signIn: createRequestAction(types.SIGN_IN),
  signUp: createRequestAction(types.SIGN_UP),
  signOut: createActionCreator(types.SIGN_OUT),
  getCurrentTherapist: createRequestAction(types.SET_CURRENT_THERAPIST),
  sendToRestore: createRequestAction(types.SEND_TO_RESTORE),
  setRestoredEmail: createActionCreator(types.SET_RESTORED_EMAIL),
  setPassword: createActionCreator(types.SET_PASSWORD),
  activateUser: createRequestAction(types.ACTIVATE_USER),
  sendToUpload: createRequestAction(types.SEND_TO_UPLOAD),
  sendToUploadDocuments: createRequestAction(types.SEND_TO_UPLOAD_DOCUMENTS),
  setKey: createActionCreator(types.SET_KEY),
  setAvatar: createActionCreator(types.SET_AVATAR),
  setAvatarNotFormatted: createActionCreator(types.SET_AVATAR_NOT_FORMATTED),
  setTherapistDocument: createActionCreator(types.SET_THERAPIST_DOCUMENT),
  updateTherapist: createRequestAction(types.UPDATE_THERAPIST),
  restoreSentFinish: createRequestAction(types.SEND_TO_RESTORE_FINISH),
  setStripeApiKey: createRequestAction(types.SET_STRIPE_API_KEY),
  setStripeToken: createActionCreator(types.SET_STRIPE_TOKEN),
  getDocuments: createRequestAction(types.GET_DOCUMENTS),
  removeDocument: createRequestAction(types.REMOVE_DOCUMENT),
  getPostCodes: createRequestAction(types.GET_POSTCODES),
  contactUs: createRequestAction(types.CONTACT_US),
};

export const handlers = {
  [types.SET_PASSWORD]: setPassword,
  [types.SET_KEY]: setKey,
  [types.SET_AVATAR]: setAvatar,
  [types.SET_AVATAR]: setAvatar,
  [types.SET_AVATAR_NOT_FORMATTED]: setAvatarNotFormatted,
  [types.SIGN_OUT]: signOut,
  [types.SET_RESTORED_EMAIL]: setRestoredEmail,
  [types.SET_THERAPIST_DOCUMENT]: setTherapistDocument,
  [types.SET_STRIPE_TOKEN]: setStripeToken,
  ...requestHelpers(types.SET_CURRENT_THERAPIST, {
    startFetching,
    requestSuccess: getCurrentTherapist,
    requestFailure,
  }),
  ...requestHelpers(types.SEND_TO_UPLOAD, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.UPDATE_THERAPIST, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.SEND_TO_UPLOAD_DOCUMENTS, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.ACTIVATE_USER, {
    startFetching,
    requestSuccess: activateUserSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.ACTIVATE_USER, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.SIGN_IN, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.SIGN_UP, {
    startFetching: setSignUpEmail,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.SEND_TO_RESTORE, {
    startFetching,
    requestFailure,
    requestSuccess: restoreSentSuccessful,
  }),
  ...requestHelpers(types.SEND_TO_RESTORE_FINISH, {
    startFetching,
    requestSuccess: restoreSentFinish,
    requestFailure,
  }),
  ...requestHelpers(types.SET_STRIPE_API_KEY, {
    startFetching,
    requestSuccess: setStripeApiKey,
    requestFailure,
  }),
  ...requestHelpers(types.GET_DOCUMENTS, {
    startFetching,
    requestSuccess: getDocuments,
    requestFailure,
  }),
  ...requestHelpers(types.REMOVE_DOCUMENT, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
  ...requestHelpers(types.GET_POSTCODES, {
    startFetching,
    requestSuccess: getPostCodes,
    requestFailure,
  }),
  ...requestHelpers(types.CONTACT_US, {
    startFetching,
    requestSuccess,
    requestFailure,
  }),
};

export default createReducer(initialState, handlers);
