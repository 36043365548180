import styled from 'styled-components';

export const ColumnViewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CustomInputPhone = styled.div`
  flex: 0 45%;
  display: flex;
  height: 115px;
  margin: 0 16px;
`;
