import React from 'react';
import TextField from '@material-ui/core/TextField';
import { object, string, bool, shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '327px',
    borderWidth: 2,
    color: '#1C1F24',
    margin: '10px 0',
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
    },
  },
}));

const Input = ({ label, input, meta: { touched, error }, ...custom }) => {
  const classes = useStyles();
  return (
    <TextField
      label={label || custom.placeholder}
      placeholder={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      className={custom.fullWidth ? '' : classes.root}
      {...input}
      {...custom}
    />
  );
};

Input.propTypes = {
  input: object.isRequired,
  label: string,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

Input.defaultProps = {
  label: null,
};

export default Input;
