import styled from 'styled-components';

export const Image = styled.img`
  border-radius: 100px;
  width: ${({ width }) => width || '112px'};
  height: ${({ height }) => height || '112px'};
  object-fit: cover;
`;

export const EmptyImage = styled.img`
  width: ${({ width }) => width || '112px'};
  height: ${({ height }) => height || '112px'};
`;

export const AvatarImage = styled.input`
  display: none;
`;
export const AvatarContainer = styled.div`
  margin-bottom: 30px;
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
`;
