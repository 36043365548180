import React, { useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import { func, object } from 'prop-types';
import { DatePicker as MaterialDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import isWithinInterval from 'date-fns/isWithinInterval';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

const useStyles = makeStyles((theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  currentDay: {
    color: '#4F2AD7',
    fontWeight: 'bold',
  },
  highlight: {
    background: 'rgba(95, 62, 212, 0.05)',
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const DatePicker = ({ getCalendarData, setSelectedWeek, location: { pathname }, history }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [dayInterval, setDayInterval] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setSelectedWeek(dayInterval);
    getCalendarData(moment(startDate).format('YYYY-MM-DD'));
  }, [dayInterval, startDate]);
  const renderWrappedWeekDay = (date, selectedDateLocal, dayInCurrentMonth, ...rest) => {
    const dateClone = new Date(date);
    const selectedDateClone = new Date(selectedDateLocal);
    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });
    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);
    if (start.getDate() !== startDate.getDate() || !dayInterval) {
      const result = eachDayOfInterval({
        start,
        end,
      });
      setStartDate(start);
      setDayInterval(result);
    }
    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.currentDay]: !!rest[0].props.current,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };
  const handleChange = (date) => {
    handleDateChange(date);
    if (!pathname.includes('dashboard/calendar')) {
      history.push('/dashboard/calendar');
    }
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MaterialDatePicker
        disableToolbar
        variant="static"
        openTo="date"
        value={selectedDate}
        renderDay={renderWrappedWeekDay}
        onChange={handleChange}
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  getCalendarData: func.isRequired,
  setSelectedWeek: func,
  location: object.isRequired,
  history: object.isRequired,
};
DatePicker.defaultProps = {
  setSelectedWeek: () => {},
};
export default DatePicker;
