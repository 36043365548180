import styled from 'styled-components';

export const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(245 248 255);
  h1 {
    padding-left: 2%;
    color: #7a7586;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  a {
    text-decoration: none;
  }
`;
export const ImageWrapper = styled.div`
  position: relative;
`;
export const EmptyReceipts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
    margin 150px 0 57px 0;
  }
  p {
    max-width: 327px;
    font-size: 16px;
    line-height: 24px;
    color: #6b7f9c;
    text-align: center;
  }
`;
export const AppointmentsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: end;
  overflow: auto;
  height: auto;
  h1 {
    flex: 1 100%;
  }
`;
export const Appointment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  max-height: 300px;
  min-width: 300px;
  padding: 24px;
  margin: 12px 2% 15px;
  flex: 0 46%;
  border-radius: 16px;
  box-shadow: 0px 3px 16px #e9ecf3;
  @media (max-width: 1100px) {
    flex: 1;
  }
`;
export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1c1f24;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #7a7586;
    opacity: 0.8;
    margin: 0;
    font-weight: bold;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
  }
`;
export const TotalPenny = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1eba66;
  border-radius: 100px;
  position: absolute;
  font-weight: 600;
  min-width: 51px;
  font-size: 14px;
  line-height: 18px;
  color: white;
  height: 27px;
  bottom: -3px;
  left: -3px;
`;
export const ButtonWrapper = styled.div`
  p {
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
    margin: 0;
  }
`;
export const AppointmentInfo = styled.div`
  margin-left: 16px;
  h2 {
    margin: 0;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const EmptyAvatar = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 16px;
  font-size: 26px;
  font-weight: bold;
  color: #5f3ed4;
  background-color: rgba(95, 62, 212, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ArrowBackImage = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;
