import React from 'react';
import { func } from 'prop-types';
import { NavigationButton, NavigationContainer, NavigationWrapper } from './NavigationStyles';
import { i18n } from '../../../constants/i18n';

const Navigation = ({ setStepCount, stepCount }) => {
  const setTab = (tabState) => {
    setStepCount(tabState);
  };
  return (
    <NavigationWrapper>
      <NavigationContainer>
        <NavigationButton activeButton={stepCount === 1 ? true : null} type="button" onClick={() => setTab(1)}>
          {i18n.personalInfo}
        </NavigationButton>
        <NavigationButton activeButton={stepCount === 2 ? true : null} type="button" onClick={() => setTab(2)}>
          {i18n.attachedFiles}
        </NavigationButton>
        <NavigationButton activeButton={stepCount === 3 ? true : null} type="button" onClick={() => setTab(3)}>
          {i18n.changePassword}
        </NavigationButton>
      </NavigationContainer>
    </NavigationWrapper>
  );
};

Navigation.propTypes = {
  setStepCount: func.isRequired,
  stepCount: func.isRequired,
};

export default Navigation;
