import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { object, string, bool, shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '../../assets/images/password_hide_icon.svg';
import VisibilityOff from '../../assets/images/password_show_icon.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '327px',
    borderColor: '#4F2AD7',
    borderWidth: 2,
    color: '#1C1F24',
    margin: '10px 0',
    '&$focused': {
      borderColor: '2px solid #4F2AD7',
    },
    '&$invalid': {
      borderColor: 'red',
    },
  },
}));

const PasswordInput = ({ label, input, meta: { touched, error }, ...custom }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      label={custom.placeholder}
      type={showPassword ? 'text' : 'password'}
      placeholder={label}
      error={touched && !!error}
      helperText={touched && error}
      className={classes.root}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {!showPassword ? (
                <img src={Visibility} alt="Visibility" />
              ) : (
                <img src={VisibilityOff} alt="Visibility off" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...input}
      {...custom}
    />
  );
};

PasswordInput.propTypes = {
  input: object.isRequired,
  label: string,
  meta: shape({ touched: bool, invalid: bool, error: string }).isRequired,
};

PasswordInput.defaultProps = {
  label: null,
};

export default PasswordInput;
