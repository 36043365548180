const EMAIL_TEMPLATE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PASSWORD_TEMPLATE = /^[\S]{6,40}$/i;
const PHONE_TEMPLATE = /^[0-9]{10}$/;
const ACCOUNT_NUMBER = /^[0-9]{8}$/;
const NAME = /^[a-zA-Z0-9-]{0,40}$/i;

export const isFieldExist = (key) => (value) => (!value[key] ? { [key]: 'Required' } : null);
export const isArrayNotEmpty = (key) => (value) => {
  return Array.isArray(value[key]) && !value[key].length ? { [key]: 'Required' } : null;
};
export const isNumber = (key) => (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (value[key] && isNaN(Number(value[key]))) {
    return { [key]: 'Must be a number' };
  } else {
    return undefined;
  }
};
export const validateByPassword = (key) => (value) => {
  if (!PASSWORD_TEMPLATE.test(value[key])) {
    return { [key]: 'Password must be at least 6 characters long.' };
  }
  return null;
};
export const validateByEmail = (key) => (value) => {
  if (!EMAIL_TEMPLATE.test(value[key])) {
    return { [key]: 'Invalid email address' };
  }
  return null;
};
export const validateByName = (key) => (value) => {
  if (!NAME.test(value[key])) {
    return { [key]: 'The field can contain only letters, numbers, and symbol ” - ”' };
  }
  return null;
};
export const validateByPhone = (key) => (value) => {
  if (!PHONE_TEMPLATE.test(value[key])) {
    return { [key]: 'Phone must be 10 characters long.' };
  }
  return null;
};
export const validateByAccountNumber = (key) => (value) => {
  if (!ACCOUNT_NUMBER.test(value[key])) {
    return { [key]: 'Account number must be a number and be 10 characters long.' };
  }
  return null;
};
export const isPasswordsIdentical = (key) => (value) => {
  if (value.password !== value.confirmPassword) {
    return { [key]: 'Passwords mismatched' };
  }
  return null;
};

export const signInFormValidation = (values) => ({
  ...validateByEmail('loginEmail')(values),
  ...isFieldExist('loginPassword')(values),
  ...isFieldExist('loginEmail')(values),
});
export const contactUsFormValidation = (values) => ({
  ...validateByEmail('email')(values),
  ...isFieldExist('name')(values),
  ...isFieldExist('email')(values),
});
export const passwordFormValidation = (values) => ({
  ...isFieldExist('password')(values),
  ...validateByPassword('password')(values),
  ...isPasswordsIdentical('confirmPassword')(values),
});
export const changePasswordFormValidation = (values) => ({
  ...isFieldExist('currentPassword')(values),
  ...validateByPassword('newPassword')(values),
  ...isFieldExist('newPassword')(values),
});
export const personalInfoFormValidation = (values) => ({
  ...isNumber('phone')(values),
  ...validateByName('firstName')(values),
  ...validateByName('lastName')(values),
  ...isFieldExist('firstName')(values),
  ...isFieldExist('lastName')(values),
  ...isFieldExist('postalCode')(values),
  ...isFieldExist('phone')(values),
  ...validateByPhone('phone')(values),
  ...isFieldExist('address1')(values),
  ...isFieldExist('city')(values),
  ...isFieldExist('state')(values),
  ...isFieldExist('dateOfBirth')(values),
});
export const editFormValidation = (values) => ({
  ...isNumber('phone')(values),
  ...validateByName('firstName')(values),
  ...validateByName('lastName')(values),
  ...isFieldExist('firstName')(values),
  ...isFieldExist('lastName')(values),
  ...isFieldExist('postalCode')(values),
  ...isFieldExist('phone')(values),
  ...validateByPhone('phone')(values),
  ...isFieldExist('address1')(values),
  ...isFieldExist('city')(values),
  ...isFieldExist('state')(values),
  ...isFieldExist('dateOfBirth')(values),
  ...isFieldExist('description')(values),
  ...isFieldExist('specializations')(values),
  ...isFieldExist('jobRole')(values),
  ...isArrayNotEmpty('specializations')(values),
});
export const infoAboutYourSelfValidation = (values) => ({
  ...isFieldExist('description')(values),
  ...isFieldExist('specializations')(values),
  ...isArrayNotEmpty('specializations')(values),
  ...isFieldExist('jobRole')(values),
});
export const EnterEmailFormValidation = (values) => ({
  ...validateByEmail('registrationEmail')(values),
});
export const documentsFormValidation = (values) => ({
  ...isFieldExist('isDocsValid')(values),
});
export const accountNumberFormValidation = (values) => ({
  ...isFieldExist('accountNumber')(values),
  ...isFieldExist('fullName')(values),
  ...isFieldExist('sortCode')(values),
  ...validateByAccountNumber('accountNumber')(values),
});
export const restorePasswordFormValidation = (values) => ({
  ...validateByEmail('restoreEmail')(values),
  ...isFieldExist('restoreEmail')(values),
});
export const appointmentNoteFormValidation = (values) => ({
  ...validateByEmail('noteText')(values),
});
