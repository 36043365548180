import styled from 'styled-components';

export const Document = styled.div`
  background: ${({ bgColor }) => bgColor || 'rgba(174, 176, 182, 0.15)'};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
`;

export const DocumentsUploader = styled.input`
  display: none;
`;
export const FieldWrapper = styled.div`
    max-width: 563px;
  width: 100%;
    display: flex;
  flex-direction: column;
  align-items: center;
  padding 0 0 16px 0;
`;
export const ActionText = styled.span`
  color: ${({ color }) => color || '#5B3FBB'};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;
export const DocInfoText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ color }) => color || '#AEB0B6'};
  span {
    color: #aeb0b6;
    &:before {
      content: '\\A';
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: #aeb0b6;
      display: inline-block;
      margin: 0 4px 2px 4px;
    }
  }
`;
export const UploaderImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 12px 16px;
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #6b7f9c;
    margin-left: 16px;
  }
  div {
    display: flex;
  }
`;
export const ActionWrapper = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  img {
    height: 20px;
    margin-right: 7px;
  }
`;
export const DocInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 16px;
  h5 {
    margin: 0 0 5px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1c1f24;
  }
  p {
    margin: 0;
  }
`;
export const StatusImage = styled.img`
  width: 40px;
  height: 40px;
`;
