import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
  width: 125px;
  height: 48px;
  background: ${({ color }) => color || 'rgba(79, 42, 215, 0.05)'};
  border-radius: 20px;
  color: ${({ textColor }) => textColor || '#5B3FBB'};
  font-weight: 800;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    opacity: 0.9;
  }
`;
export const DialogTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #1c1f24;
  width: 100%;
  margin: 0 0 18px 0;
`;
export const DialogText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1f24;
  width: 100%;
  margin-bottom: 30px;
`;
