import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import logoDashboard from '../../../assets/images/logoDashboard.png';
import { LeftBarWrapper, Logo, LogOut } from './LeftBarStyles';
import { actions } from '../../../redux/authorization/authorization';
import logout from '../../../assets/images/logout.svg';
import ConfirmDialogLogout from '../ConfirmDialogLogout/ConfirmDialogLogout';
import { actions as dialogAction } from '../../../redux/dialog/dialog';
import { selectConfirmDialogLogoutState } from '../../../selectors/dialogSelector';

const LeftBar = ({ signOut, dialogState, toggleDialog }) => {
  return (
    <LeftBarWrapper>
      <Link to="/dashboard">
        <Logo src={logoDashboard} alt="dashboard logo" />
      </Link>
      <LogOut onClick={() => toggleDialog(dialogState)}>
        <img src={logout} alt="log out" />
      </LogOut>
      <ConfirmDialogLogout dialogState={dialogState} toggleDialog={toggleDialog} confirm={signOut} />
    </LeftBarWrapper>
  );
};

LeftBar.propTypes = {
  signOut: func.isRequired,
  toggleDialog: func.isRequired,
  dialogState: bool,
};
LeftBar.defaultProps = {
  dialogState: false,
};
const mapStateToProps = createStructuredSelector({
  dialogState: (state) => selectConfirmDialogLogoutState(state),
});
const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(actions.signOut()),
  toggleDialog: (payload) => dispatch(dialogAction.toggleConfirmDialogLogoutState(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
