import styled from 'styled-components';

export const ChangePasswordFormWrapper = styled.div`
  background: white;
  width: 100%;
  padding: 24px 24px;
  position: relative;
  min-height: 315px;
  span {
    font-size: 16px;
    line-height: 22px;
    color: #7a8697;
  }
  form {
    padding: 32px 48px;
  }
`;
