import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  position: relative;
`;
export const NavigationContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  position: fixed;
  padding: 8px 0 8px 8px;
`;
export const NavigationButton = styled.button`
  font-weight: 500;
  height: 56px;
  border-radius: 8px 0px 0px 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding-left: 24px;
  outline: none;
  color: ${({ activeButton }) => (activeButton ? '#5B3FBB' : '#6b7f9c')};
  background: ${({ activeButton }) => (activeButton ? 'rgba(239, 236, 249)' : 'transparent')};
  border: none;
  border-left: ${({ activeButton }) => (activeButton ? '6px solid #5B3FBB' : 'none')};
`;
