import React from 'react';
import { Dialog as BluePrintDialog, Icon } from '@blueprintjs/core';
import { arrayOf, element, bool, func, object, oneOfType } from 'prop-types';
import { CloseButton, DialogBody } from './DialogStyles';

const icon = 'cross';
const iconSize = '24px';
const color = '#AEB0B6';
const dialogStyle = {
  backgroundColor: 'white',
  width: '700px',
  borderRadius: '16px',
  zIndex: 500,
};

const Dialog = ({ children, isOpen, closeDialog, dialogCustomStyle, ...rest }) => {
  const toggleCloseDialog = () => closeDialog(true);
  return (
    <BluePrintDialog style={dialogCustomStyle || dialogStyle} isOpen={isOpen} {...rest}>
      <CloseButton>
        <Icon onClick={toggleCloseDialog} icon={icon} iconSize={iconSize} color={color} />
      </CloseButton>
      <DialogBody>{children}</DialogBody>
    </BluePrintDialog>
  );
};

Dialog.propTypes = {
  children: oneOfType([arrayOf(element), element]).isRequired,
  isOpen: bool,
  closeDialog: func,
  dialogCustomStyle: object,
};
Dialog.defaultProps = {
  isOpen: false,
  closeDialog: () => null,
  dialogCustomStyle: null,
};
export default Dialog;
