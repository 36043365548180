import { createSelector } from 'reselect';
import get from 'lodash/get';
import moment from 'moment';
import { NAME } from '../redux/dashboard/dashboard';

const selectDashboard = (state) => state[NAME];

export const selectTherapistAppointments = createSelector(selectDashboard, ({ appointments }) => {
  const now = Date.now();
  const upcoming =
    (appointments &&
      appointments
        .filter(item => !!item.client)
        .reduce((acc, el) => {
          const endTime = moment(el.endTime, 'YYYY-MM-DD HH:mm:ss');
          if (now < endTime) {
            return [...acc, el];
          }
          return acc;
        }, [])) ||
    [];
  const previous =
    (appointments &&
      appointments
        .filter(item => !!item.client)
        .reduce((acc, el) => {
          const endTime = moment(el.endTime, 'YYYY-MM-DD HH:mm:ss');
          if (now > endTime) {
            return [...acc, el];
          }
          return acc;
        }, [])) ||
    [];
  return { upcoming, previous };
});

export const selectEventData = createSelector(selectDashboard, ({ eventDate }) => eventDate);

export const selectSelectedWeek = createSelector(selectDashboard, ({ selectedWeek }) => selectedWeek) || [];

export const selectRecurrentAvailability = createSelector(
  selectDashboard,
  ({ calendarData }) => calendarData && calendarData.recurrentAvailability,
);

export const selectCalendarData = createSelector(
  selectDashboard,
  (state) => state,
  ({ calendarData, selectedWeek }) => {
    const selectedWeekFormatted =
      (selectedWeek &&
        Object.fromEntries(
          Object.entries(selectedWeek).map(([key, value]) => {
            return [moment(value).format('dddd').toLowerCase(), { date: moment(value).format('YYYY-MM-DD') }];
          }),
        )) ||
      {};
    const available =
      (get(calendarData, 'customAvailability.available') &&
        calendarData.customAvailability.available.flatMap((el) => {
          return el.times.map((time) => {
            return {
              title: 'Available',
              isRecurrent: false,
              id: time.id,
              start: new Date(`${el.date} ${time.from}`),
              end: new Date(`${el.date} ${time.to}`),
            };
          });
        })) ||
      [];
    const unavailable =
      (get(calendarData, 'custocurrentNotemAvailability.unavailable') &&
        calendarData.customAvailability.unavailable.flatMap((el) => {
          return el.times.map((time) => {
            return {
              title: 'Unavailable',
              isRecurrent: false,
              id: time.id,
              start: new Date(`${el.date} ${time.from}`),
              end: new Date(`${el.date} ${time.to}`),
            };
          });
        })) ||
      [];
    const repeatDays =
      (get(calendarData, 'recurrentAvailability') &&
        Object.entries(calendarData.recurrentAvailability).flatMap(([key, value]) => {
          return value.map(({ from, to }) => {
            return {
              title: 'Available',
              isRecurrent: true,
              start: new Date(`${get(selectedWeekFormatted[key], 'date')} ${from}`),
              end: new Date(`${get(selectedWeekFormatted[key], 'date')} ${to}`),
            };
          });
        })) ||
      [];
    return [...available, ...unavailable, ...repeatDays];
  },
);
export const selectedRoomData = createSelector(selectDashboard, ({ roomData }) => roomData);
export const selectedReceiptsData = createSelector(selectDashboard, ({ receiptsData }) => receiptsData);
export const selectedReceiptPdfFile = createSelector(selectDashboard, ({ pdfReceiptFile }) => pdfReceiptFile);
export const selectedCanceledAppointment = createSelector(
  selectDashboard,
  ({ canceledAppointment }) => canceledAppointment,
);
export const selectedCurrentNote = createSelector(selectDashboard, ({ currentNote }) => currentNote);
export const selectedCurrentNoteFromApi = createSelector(
  selectDashboard,
  ({ currentNoteFromApi }) => currentNoteFromApi,
);
export const selectSuccessMessage = createSelector(selectDashboard, ({ successMessage }) => successMessage);
