import styled from 'styled-components';

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
  * {
    cursor: pointer;
    &:hover {
      color: grey;
    }
  }
`;
export const DialogBody = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px 5px;
  h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
  }
  form {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    span {
      a {
        color: #999696;
        text-decoration: none;
      }
    }
  }
`;
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding 0 0 16px 0;
  input {
    width: 327px;
  }
`;
