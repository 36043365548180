import React, { useEffect } from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { func, array } from 'prop-types';
import DocumentField from '../DocumentField/DocumentField';
import { DocumentWrapper } from './AddDocumentsFormStyles';
import { documentsFormValidation } from '../../../../../utils/validators';
import Input from '../../../../Input/Input';

const AddDocumentsForm = ({ saveDocuments, documents, removeDocument, initialize }) => {
  useEffect(() => {
    const checkDocs = documents.filter((el) => el.title).length;
    if (checkDocs === 14) {
      initialize({ isDocsValid: 'valid' });
    } else {
      initialize({ isDocsValid: null });
    }
  }, [documents]);
  return (
    <DocumentWrapper>
      <Field style={{ display: 'none' }} component={Input} name="isDocsValid" />
      <Form onSubmit={() => {}}>
        {documents &&
          documents.map((doc) => (
            <DocumentField
              key={doc.documentType}
              doc={doc}
              documents={documents}
              saveDocuments={saveDocuments}
              removeDocument={removeDocument}
            />
          ))}
      </Form>
    </DocumentWrapper>
  );
};

AddDocumentsForm.propTypes = {
  saveDocuments: func.isRequired,
  removeDocument: func.isRequired,
  initialize: func.isRequired,
  documents: array,
};
AddDocumentsForm.defaultProps = {
  documents: null,
};

export default reduxForm({
  form: 'documentsForm',
  enableReinitialize: true,
  validate: documentsFormValidation,
})(AddDocumentsForm);
