import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
`;
export const AppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 15px;
  align-items: center;
  background: rgba(245 248 255);
  justify-content: end;
`;
export const EmptyAppointments = styled.div`
  img {
    margin 150px 0 57px 0;
  }
  p {
    max-width: 327px;
    font-size: 16px;
    line-height: 24px;
    color: #6b7f9c;
    text-align: center;
  }
`;
export const HeadWrapper = styled.div`
  width: 100%;
  h1 {
    padding-left: 2%;
  }
`;
export const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    padding-left: 2%;
    color: #7a7586;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const AppointmentsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: end;
  overflow: auto;
  height: calc(100vh - 130px);
  h1 {
    flex: 1 100%;
  }
`;
export const Appointment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  max-height: 300px;
  min-width: 300px;
  padding: 24px;
  margin: 12px 2% 15px;
  flex: 0 46%;
  border-radius: 20px;
  box-shadow: 0px 4px 24px #eeeef2;
  @media (max-width: 1100px) {
    flex: 1;
  }
`;
export const LeftBlock = styled.div`
  h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    opacity: 0.6;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    opacity: 0.6;
    margin: 0;
  }
`;
export const AvatarImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 16px;
`;
export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }
`;
export const ButtonWrapper = styled.div`
  p {
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
  }
`;
export const AppointmentButton = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 100px;
  font-weight: bold;
  font-size: 16px;
  background: ${({ bgColor }) => bgColor || 'rgba(217, 51, 51, 0.05)'};
  color: ${({ textColor }) => textColor || '#D93333'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
`;
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EmptyAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: bold;
  color: #5f3ed4;
  background-color: rgba(95, 62, 212, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;
