import React from 'react';
import { bool, func } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonWrapper, HeaderWrapper, Logo, NavBar, SignInButton, SignUpButton } from './HeaderStyles';
import {
  selectContactUsDialogState,
  selectSignInDialogState,
  selectSignUpDialogState,
} from '../../selectors/dialogSelector';
import { actions as dialogAction } from '../../redux/dialog/dialog';
import { i18n } from '../../constants/i18n';
import logo from '../../assets/images/logo.png';

const buttonBg = 'transparent';

const Header = ({
  toggleSignInDialog,
  toggleSignUpDialog,
  toggleContactUsDialog,
  contactUsDialogState,
  signInDialogState,
  signUpDialogState,
}) => {
  return (
    <NavBar>
      <HeaderWrapper>
        <Link to="/">
          <Logo src={logo} alt="logo" />
        </Link>
        <ButtonWrapper>
          <div>
            <SignInButton onClick={() => toggleSignInDialog(signInDialogState)} type="button">
              {i18n.logIn}
            </SignInButton>
            <SignUpButton onClick={() => toggleSignUpDialog(signUpDialogState)} background={buttonBg} type="button">
              {i18n.joinAs}
            </SignUpButton>
          </div>
          <SignUpButton onClick={() => toggleContactUsDialog(contactUsDialogState)} background={buttonBg} type="button">
            {i18n.expressInterest}
          </SignUpButton>
        </ButtonWrapper>
      </HeaderWrapper>
    </NavBar>
  );
};
Header.propTypes = {
  signInDialogState: bool,
  signUpDialogState: bool,
  contactUsDialogState: bool,
  toggleSignInDialog: func.isRequired,
  toggleSignUpDialog: func.isRequired,
  toggleContactUsDialog: func.isRequired,
};
Header.defaultProps = {
  signInDialogState: false,
  signUpDialogState: false,
  contactUsDialogState: false,
};

const mapStateToProps = createStructuredSelector({
  signInDialogState: (state) => selectSignInDialogState(state),
  signUpDialogState: (state) => selectSignUpDialogState(state),
  contactUsDialogState: (state) => selectContactUsDialogState(state),
});
const mapDispatchToProps = (dispatch) => ({
  toggleSignInDialog: (payload) => dispatch(dialogAction.signInToggleDialog(payload)),
  toggleSignUpDialog: (payload) => dispatch(dialogAction.signUpToggleDialog(payload)),
  toggleContactUsDialog: (payload) => dispatch(dialogAction.toggleContactUsDialog(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
