import { call, put, takeLatest, all, select, take } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import { types, actions } from '../../redux/authorization/authorization';
import { actions as dialogActions } from '../../redux/dialog/dialog';
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '../../utils/localStor';
import {
  createUser,
  restore,
  authorize,
  sendToActivation,
  uploadAvatarUtil,
  uploadDocFilesUtil,
  getCurrentTherapist,
  updateTherapistUtil,
  restoreFinishUtil,
  getStripeTokenUtil,
  removeDocumentUtil,
  getAllDocumentsUtil,
  getPostCodesUtil,
  contactUsUtil,
} from '../../utils/authSagaHelpers';
import { STATES_CONST } from '../../constants/states';

function* signOut() {
  removeFromLocalStorage('token');
  removeFromLocalStorage('therapistStatus');
  yield put(push('/account'));
}

function* signUp({ payload: { email } }) {
  const { showCheckYourMail } = yield select((store) => ({
    ...store.dialog,
  }));
  try {
    const payloadData = yield call(createUser, email);
    yield put(actions.signUp.success(payloadData));
    yield put(dialogActions.toggleCheckYourMail(showCheckYourMail));
  } catch (error) {
    yield put(actions.signUp.failure(error));
  }
}

function* signIn({ payload: { email, password } }) {
  const { waitForApproveState, signUpStepTwoDialogState } = yield select((store) => ({
    ...store.dialog,
  }));
  try {
    const payloadData = yield call(authorize, email, password);
    saveToLocalStorage(payloadData, 'token');
    yield put(dialogActions.signInToggleDialog(payloadData));
    yield put(actions.signIn.success(payloadData));
    yield put(actions.getCurrentTherapist.request());
    yield take(types.SET_CURRENT_THERAPIST.SUCCESS);
    const status = getFromLocalStorage('therapistStatus');
    if (status === 'NEW') {
      yield put(push('/account'));
      yield put(dialogActions.toggleWaitForApprove(waitForApproveState));
    }
    if (status === 'APPROVED') {
      yield put(push('/dashboard'));
    }
    if (status === 'SUSPENDED') {
      yield put(push('/account'));
      yield put(dialogActions.signUpStepTwoDialogState(signUpStepTwoDialogState));
    }
  } catch (error) {
    yield put(actions.signIn.failure(error));
  }
}
function* getTherapistData() {
  try {
    const payloadData = yield call(getCurrentTherapist);
    saveToLocalStorage(payloadData.status, 'therapistStatus');
    yield put(actions.getCurrentTherapist.success(payloadData));
  } catch (error) {
    yield put(actions.getCurrentTherapist.failure(error));
  }
}

function* restorePassword({ payload }) {
  const { email } = payload;
  try {
    yield call(restore, { email });
    yield put(actions.sendToRestore.success());
  } catch (error) {
    yield put(actions.sendToRestore.failure(error.message));
  }
}

function* restorePasswordFinish({ payload }) {
  const { key, signInDialogState } = yield select((store) => ({
    ...store.authorization,
    ...store.dialog,
  }));
  try {
    yield call(restoreFinishUtil, payload, key);
    yield put(actions.restoreSentFinish.success());
    yield put(push('/account'));
    yield put(dialogActions.signInToggleDialog(signInDialogState));
  } catch (error) {
    yield put(actions.restoreSentFinish.failure(error.message));
  }
}

function* activateUser({ payload: userDetails }) {
  const {
    userPassword,
    key,
    avatarImage,
    stripeToken: {
      id,
      accountNumber,
      // eslint-disable-next-line camelcase
      bank_account: { account_holder_name, routing_number },
    },
  } = yield select((store) => ({
    ...store.authorization,
  }));
  const stateCode = STATES_CONST.find(({ name }) => name.toUpperCase() === userDetails.state);
  try {
    const userData = yield call(
      sendToActivation,
      {
        ...userDetails,
        state: stateCode.code,
        password: userPassword,
        tokenExternalAccount: id || 'empty token',
        accountName: account_holder_name,
        accountNumber,
        sortCode: routing_number,
      },
      key,
    );
    yield put(actions.activateUser.success(userData));
    const payloadData = yield call(authorize, userData.email, userPassword);
    saveToLocalStorage(payloadData, 'token');
    // yield put(actions.signIn.request({ email: userData.email, password: userPassword }));
    if (avatarImage) {
      yield put(actions.sendToUpload.request(avatarImage));
    }
  } catch (error) {
    yield put(actions.activateUser.failure(error));
  }
}
function* updateTherapist({ payload }) {
  const { avatarImage, therapistDocument } = yield select((store) => ({
    ...store.authorization,
  }));
  try {
    const userData = yield call(updateTherapistUtil, payload);
    yield put(actions.updateTherapist.success(userData));
    yield put(actions.getCurrentTherapist.request());
    if (avatarImage) {
      yield put(actions.sendToUpload.request(avatarImage));
      yield take(types.SEND_TO_UPLOAD.SUCCESS);
    }
    if (therapistDocument && therapistDocument.length) {
      yield put(actions.sendToUploadDocuments.request(therapistDocument));
    }
    yield put(actions.getCurrentTherapist.request());
    yield put(push('/dashboard'));
  } catch (error) {
    yield put(actions.updateTherapist.failure(error));
  }
}

function* uploadAvatar({ payload }) {
  try {
    yield call(uploadAvatarUtil, payload);
    yield put(actions.sendToUpload.success());
  } catch (error) {
    yield put(actions.sendToUpload.failure(error));
  }
}
function* uploadDocument({ payload }) {
  try {
    yield call(uploadDocFilesUtil, payload);
    yield put(actions.sendToUploadDocuments.success());
    yield put(actions.getDocuments.request());
  } catch (error) {
    yield put(actions.sendToUploadDocuments.failure(error));
  }
}
function* removeDocument({ payload }) {
  try {
    yield call(removeDocumentUtil, payload);
    yield put(actions.removeDocument.success());
    yield put(actions.getDocuments.request());
  } catch (error) {
    yield put(actions.removeDocument.failure(error));
  }
}
function* getAllDocuments() {
  try {
    const payloadData = yield call(getAllDocumentsUtil);
    yield put(actions.getDocuments.success(payloadData));
  } catch (error) {
    yield put(actions.getDocuments.failure(error));
  }
}
function* getStripeToken({ payload }) {
  try {
    const payloadData = yield call(getStripeTokenUtil, payload);
    yield put(actions.setStripeApiKey.success(payloadData));
  } catch (error) {
    yield put(actions.setStripeApiKey.failure(error));
  }
}

function* getPostCodes({ payload }) {
  try {
    const payloadData = yield call(getPostCodesUtil, payload);
    yield put(actions.getPostCodes.success(payloadData));
  } catch (error) {
    yield put(actions.getPostCodes.failure(error));
  }
}
function* contactUs({ payload }) {
  const { contactUsSuccessDialogState } = yield select((store) => ({
    ...store.dialog,
  }));
  try {
    const payloadData = yield call(contactUsUtil, payload);
    yield put(actions.contactUs.success(payloadData));
    yield put(dialogActions.toggleContactUsSuccessDialog(contactUsSuccessDialogState));
  } catch (error) {
    yield put(actions.contactUs.failure(error));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.SIGN_OUT, signOut),
    yield takeLatest(types.SIGN_UP.REQUEST, signUp),
    yield takeLatest(types.SIGN_IN.REQUEST, signIn),
    yield takeLatest(types.SEND_TO_RESTORE.REQUEST, restorePassword),
    yield takeLatest(types.SEND_TO_RESTORE_FINISH.REQUEST, restorePasswordFinish),
    yield takeLatest(types.ACTIVATE_USER.REQUEST, activateUser),
    yield takeLatest(types.SEND_TO_UPLOAD.REQUEST, uploadAvatar),
    yield takeLatest(types.SEND_TO_UPLOAD_DOCUMENTS.REQUEST, uploadDocument),
    yield takeLatest(types.SET_CURRENT_THERAPIST.REQUEST, getTherapistData),
    yield takeLatest(types.UPDATE_THERAPIST.REQUEST, updateTherapist),
    yield takeLatest(types.SET_STRIPE_API_KEY.REQUEST, getStripeToken),
    yield takeLatest(types.REMOVE_DOCUMENT.REQUEST, removeDocument),
    yield takeLatest(types.GET_DOCUMENTS.REQUEST, getAllDocuments),
    yield takeLatest(types.GET_POSTCODES.REQUEST, getPostCodes),
    yield takeLatest(types.CONTACT_US.REQUEST, contactUs),
  ]);
}
