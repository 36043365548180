import axios, { get, post, put, patch } from 'axios';

import * as _ from 'lodash';

import {
  APPOINTMENTS_NOTE,
  CANCEL_APPOINTMENT,
  CHANGE_PASSWORD,
  CONNECT_TO_ROOM,
  DELETE_CUSTOM_AVAILABILITY,
  RECEIPT_PDF,
  RECEIPTS_THERAPIST,
  THERAPIST_APPOINTMENTS,
  THERAPIST_APPOINTMENTS_ID,
  THERAPIST_CALENDAR_CUSTOM,
  THERAPIST_CALENDAR_ME,
  THERAPIST_RECURRENT_AVAILABILITY,
} from '../constants/api';
import { getFromLocalStorage } from './localStor';

export const getTherapistAppointmentsHelper = async () => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(THERAPIST_APPOINTMENTS, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page: 0,
        size: 100,
      },
    });
    return result.data.content;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getCurrentNoteHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(THERAPIST_APPOINTMENTS_ID(payload), {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getTherapistCalendarDataHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(THERAPIST_CALENDAR_ME, {
      headers: { Authorization: `Bearer ${token}` },
      params: { startDate: payload },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getReceiptsHelper = async () => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(RECEIPTS_THERAPIST, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page: 0,
        size: 20,
      },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getReceiptPdfFileHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(RECEIPT_PDF(payload), {
      headers: { Authorization: `Bearer ${token}`, Accept: 'application/pdf' },
      responseType: 'blob',
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};

export const addTherapistCalendarCustomHelper = async ({ payload: { isUnavailable, from, to } }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await post(
      THERAPIST_CALENDAR_CUSTOM,
      { isUnavailable, from, to },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      },
    );
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const changePasswordHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await post(CHANGE_PASSWORD, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail') || _.get(error, 'response.data.title');
    throw errorData;
  }
};
export const addTherapistRecurrentAvailabilityHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await put(THERAPIST_RECURRENT_AVAILABILITY, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const connectToRoomHelper = async ({ id }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(CONNECT_TO_ROOM(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return _.get(result, 'data', null);
  } catch (error) {
    const errorData =
      _.get(error, 'response.data.detail', null) ||
      _.get(error, 'response.data.title', null) ||
      _.get(error, 'response.data.message', null);
    throw errorData;
  }
};

export const cancelAppointmentHelper = async ({ id }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await patch(CANCEL_APPOINTMENT(id), null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return _.get(result, 'data', null);
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};

export const deleteCustomAvailabilityHelper = async (payload) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = axios.delete(DELETE_CUSTOM_AVAILABILITY(payload), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return _.get(result, 'data', null);
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};

export const updateAppointmentNoteHelper = async ({ payload }) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await patch(APPOINTMENTS_NOTE(payload.id), null, {
      params: { note: payload.note },
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
