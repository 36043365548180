import React from 'react';
import { element, oneOfType, string } from 'prop-types';
import { CustomButtonContainer } from './ButtonStyles';

const Button = ({ children, ...rest }) => <CustomButtonContainer {...rest}>{children}</CustomButtonContainer>;

Button.propTypes = {
  children: oneOfType([element, string]).isRequired,
};

export default Button;
