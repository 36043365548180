import React, { Suspense, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { object, func } from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import ReactPixel from 'react-facebook-pixel';
import AuthRouter from './routes/AuthRoutes/AuthRouter';
import Spinner from './components/Spinner/Spinner';
import DashboardRouter from './routes/DashboardRoutes/DashboardRouter';
import './App.css';
import { actions } from './redux/authorization/authorization';
import HandleErrors from './components/HandleErrors/HandleErrors';
import { getFromLocalStorageAsync } from './utils/localStor';
import PrivateRoute from './components/Common/PrivateRoute';

const App = ({ history, getTherapistData }) => {
  useEffect(() => {
    if (history.location.pathname.includes('dashboard')) {
      getTherapistData();
    }
  }, [history]);
  useEffect(() => {
    ReactPixel.init('978031765977929');
    ReactPixel.pageView(); // For tracking page view
  }, []);
  const isAuthenticated = getFromLocalStorageAsync('token');
  return (
    <ConnectedRouter history={history}>
      <HandleErrors />
      <Suspense fallback={<Spinner />}>
        <Route exact path="/" render={() => <Redirect to="/account" />} />
        <Route path="/account" component={AuthRouter} />
        <PrivateRoute path="/dashboard" component={DashboardRouter} isAuthenticated={isAuthenticated} />
        <Route
          path="/apple-app-site-association"
          component={<link rel="apple-app-site-association" href="%PUBLIC_URL%/apple-app-site-association" />}
        />
      </Suspense>
    </ConnectedRouter>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTherapistData: () => dispatch(actions.getCurrentTherapist.request()),
});

App.propTypes = {
  history: object.isRequired,
  getTherapistData: func.isRequired,
};

export default connect(null, mapDispatchToProps)(App);
