import React from 'react';
import { func, bool } from 'prop-types';
import Dialog from '../../Dialog/Dialog';
import { i18n } from '../../../constants/i18n';
import { Button, ButtonWrapper, DialogText, DialogTitle } from './ConfirmDialogLogoutStyles';

const portal = document.getElementById('modal');
const dialogStyle = {
  backgroundColor: 'white',
  maxWidth: '356px',
  width: '100%',
};

const ConfirmDialogLogout = ({ dialogState, toggleDialog, confirm }) => {
  const onSubmit = () => {
    toggleDialog(dialogState);
    confirm();
  };
  return (
    <Dialog
      portalContainer={portal}
      canOutsideClickClose={false}
      isOpen={dialogState}
      closeDialog={toggleDialog}
      dialogCustomStyle={dialogStyle}
    >
      <DialogTitle>{i18n.logOut}</DialogTitle>
      <DialogText>{i18n.areYourSure}</DialogText>
      <ButtonWrapper>
        <Button onClick={() => toggleDialog(dialogState)} type="button">
          {i18n.cancel}
        </Button>
        <Button color="rgba(251, 81, 89, 0.05)" textColor="#FB5159" onClick={onSubmit} type="button">
          {i18n.logOut}
        </Button>
      </ButtonWrapper>
    </Dialog>
  );
};
ConfirmDialogLogout.propTypes = {
  toggleDialog: func.isRequired,
  dialogState: bool,
  confirm: func.isRequired,
};
ConfirmDialogLogout.defaultProps = {
  dialogState: false,
};
export default ConfirmDialogLogout;
