import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import moment from 'moment';
import { values } from 'lodash';
import { push } from 'connected-react-router';
import { actions, types } from '../../redux/dashboard/dashboard';
import {
  addTherapistCalendarCustomHelper,
  addTherapistRecurrentAvailabilityHelper,
  cancelAppointmentHelper,
  changePasswordHelper,
  connectToRoomHelper,
  deleteCustomAvailabilityHelper,
  getReceiptPdfFileHelper,
  getReceiptsHelper,
  getTherapistAppointmentsHelper,
  getTherapistCalendarDataHelper,
  updateAppointmentNoteHelper,
  getCurrentNoteHelper,
} from '../../utils/dashboardSagaHelpers';
import { actions as dialogActions } from '../../redux/dialog/dialog';
import { removeFromLocalStorage } from '../../utils/localStor';

function* getTherapistAppointments() {
  try {
    const payloadData = yield call(getTherapistAppointmentsHelper);
    yield put(actions.getAppointments.success(payloadData));
  } catch (error) {
    yield put(actions.getAppointments.failure(error));
  }
}
function* getReceipts() {
  try {
    const payloadData = yield call(getReceiptsHelper);
    yield put(actions.getReceipts.success(payloadData));
  } catch (error) {
    yield put(actions.getReceipts.failure(error));
  }
}
function* getReceiptPdfFile(payload) {
  try {
    const payloadData = yield call(getReceiptPdfFileHelper, payload);
    yield put(actions.getReceiptPdfFile.success(payloadData));
  } catch (error) {
    yield put(actions.getReceiptPdfFile.failure(error));
  }
}
function* changePassword(payload) {
  const { signInDialogState } = yield select((store) => ({
    ...store.dialog,
  }));
  try {
    const payloadData = yield call(changePasswordHelper, payload);
    yield put(actions.changePassword.success(payloadData));
    removeFromLocalStorage('token');
    removeFromLocalStorage('therapistStatus');
    yield put(push('/account'));
    yield put(dialogActions.signInToggleDialog(signInDialogState));
  } catch (error) {
    yield put(actions.changePassword.failure(error));
  }
}
function* getTherapistAvailabilityData(payload) {
  try {
    const payloadData = yield call(getTherapistCalendarDataHelper, payload);
    yield put(actions.getCalendarData.success(payloadData));
  } catch (error) {
    yield put(actions.getCalendarData.failure(error));
  }
}
function* getCurrentNote(payload) {
  try {
    const payloadData = yield call(getCurrentNoteHelper, payload);
    yield put(actions.getCurrentNote.success(payloadData));
  } catch (error) {
    yield put(actions.getCurrentNote.failure(error));
  }
}
function* addTherapistCustomAvailability(payload) {
  const { selectedWeek } = yield select((store) => ({
    ...store.dashboard,
  }));
  const currentDate = values(selectedWeek)[0];
  const formatDate = moment(currentDate).format('YYYY-MM-DD');
  try {
    const payloadData = yield call(addTherapistCalendarCustomHelper, payload);
    yield put(actions.addCalendarCustomAvailability.success(payloadData));
    yield put(actions.getCalendarData.request(formatDate));
    yield put(dialogActions.toggleAvailabilityDialogState(payloadData));
  } catch (error) {
    yield put(actions.addCalendarCustomAvailability.failure(error));
  }
}

function* addTherapistRecurrentAvailability(payload) {
  const { selectedWeek } = yield select((store) => ({
    ...store.dashboard,
  }));
  const currentDate = values(selectedWeek)[0];
  const formatDate = moment(currentDate).format('YYYY-MM-DD');
  try {
    const payloadData = yield call(addTherapistRecurrentAvailabilityHelper, payload);
    yield put(actions.addCalendarRecurrentAvailability.success(payloadData));
    yield put(actions.getCalendarData.request(formatDate));
    yield put(dialogActions.toggleAvailabilityDialogState(payloadData));
  } catch (error) {
    yield put(actions.addCalendarRecurrentAvailability.failure(error));
  }
}

function* connectToRoom({ payload }) {
  try {
    const result = yield call(connectToRoomHelper, payload);
    yield put(actions.connectToRoom.success(result));
  } catch (error) {
    yield put(actions.connectToRoom.failure(error));
    yield put(push('/dashboard'));
  }
}
function* cancelAppointment({ payload }) {
  try {
    const result = yield call(cancelAppointmentHelper, payload);
    yield put(actions.cancelAppointment.success(result));
  } catch (error) {
    yield put(actions.cancelAppointment.failure(error));
  }
}
function* updateAppointmentNote(payload) {
  const { appointmentNoteDialogState } = yield select((store) => ({
    ...store.dialog,
  }));
  try {
    const result = yield call(updateAppointmentNoteHelper, payload);
    yield put(actions.updateAppointmentNote.success(result));
    yield put(actions.getAppointments.request());
    if (!appointmentNoteDialogState) {
      yield put(dialogActions.toggleAppointmentNoteDialog(appointmentNoteDialogState));
    }
  } catch (error) {
    yield put(actions.updateAppointmentNote.failure(error));
  }
}

function* deleteCustomAvailability({ payload }) {
  const { selectedWeek } = yield select((store) => ({
    ...store.dashboard,
  }));
  const currentDate = values(selectedWeek)[0];
  const formatDate = moment(currentDate).format('YYYY-MM-DD');
  try {
    yield call(deleteCustomAvailabilityHelper, payload);
    yield put(actions.deleteCustomAvailability.success());
    yield delay(500);
    yield put(actions.getCalendarData.request(formatDate));
  } catch (error) {
    yield put(actions.deleteCustomAvailability.failure(error));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_APPOINTMENTS.REQUEST, getTherapistAppointments),
    yield takeLatest(types.GET_CALENDAR_DATA.REQUEST, getTherapistAvailabilityData),
    yield takeLatest(types.ADD_CALENDAR_CUSTOM_AVAILABILITY.REQUEST, addTherapistCustomAvailability),
    yield takeLatest(types.ADD_CALENDAR_RECURRENT_AVAILABILITY.REQUEST, addTherapistRecurrentAvailability),
    yield takeLatest(types.CONNECT_TO_ROOM.REQUEST, connectToRoom),
    yield takeLatest(types.CANCEL_APPOINTMENT.REQUEST, cancelAppointment),
    yield takeLatest(types.DELETE_CUSTOM_AVAILABILITY.REQUEST, deleteCustomAvailability),
    yield takeLatest(types.UPDATE_APPOINTMENT_NOTE.REQUEST, updateAppointmentNote),
    yield takeLatest(types.GET_RECEIPTS.REQUEST, getReceipts),
    yield takeLatest(types.GET_RECEIPT_PDF_FILE.REQUEST, getReceiptPdfFile),
    yield takeLatest(types.CHANGE_PASSWORD.REQUEST, changePassword),
    yield takeLatest(types.GET_CURRENT_NOTE.REQUEST, getCurrentNote),
  ]);
}
