import { createSelector } from 'reselect';
import { NAME } from '../redux/dialog/dialog';

const selectDialog = (state) => state[NAME];

export const selectSignInDialogState = createSelector(selectDialog, ({ signInDialogState }) => signInDialogState);

export const selectSignUpDialogState = createSelector(selectDialog, ({ signUpDialogState }) => signUpDialogState);

export const selectRestoreDialogState = createSelector(selectDialog, ({ restoreDialogState }) => restoreDialogState);

export const selectSignUpStepTwoDialogState = createSelector(
  selectDialog,
  ({ signUpStepTwoDialogState }) => signUpStepTwoDialogState,
);

export const selectCheckEmailDialogState = createSelector(selectDialog, ({ showCheckYourMail }) => showCheckYourMail);

export const selectCheckStatusDialogState = createSelector(
  selectDialog,
  ({ waitForApproveState }) => waitForApproveState,
);

export const selectFormState = createSelector(selectDialog, ({ showRestorePassword }) => showRestorePassword);

export const selectAvailabilityState = createSelector(
  selectDialog,
  ({ availabilityDialogState }) => availabilityDialogState,
);

export const selectEditAndDeleteAvailabilityState = createSelector(
  selectDialog,
  ({ editAndDeleteDialogState }) => editAndDeleteDialogState,
);

export const selectConfirmDialogAppointmentsState = createSelector(
  selectDialog,
  ({ confirmDialogAppointmentsState }) => confirmDialogAppointmentsState,
);

export const selectConfirmDialogLogoutState = createSelector(
  selectDialog,
  ({ confirmDialogLogoutState }) => confirmDialogLogoutState,
);
export const selectAppointmentNoteDialogState = createSelector(
  selectDialog,
  ({ appointmentNoteDialogState }) => appointmentNoteDialogState,
);
export const selectContactUsDialogState = createSelector(
  selectDialog,
  ({ contactUsDialogState }) => contactUsDialogState,
);
export const selectContactUsSuccessDialogState = createSelector(
  selectDialog,
  ({ contactUsSuccessDialogState }) => contactUsSuccessDialogState,
);
