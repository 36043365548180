import React, { useState } from 'react';
import { func, string, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { AvatarContainer, AvatarImage, EmptyImage, Image } from './AvatarStyles';
import EmptyAvatarImage from '../../assets/images/avatar.png';
import { selectUserAvatar } from '../../selectors/authorizationSelector';
import { actions } from '../../redux/authorization/authorization';

const Avatar = ({ imageSrc, setAvatar, avatar, setAvatarNotFormatted, ...rest }) => {
  const [imagePath, setImagePath] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
    const base64 = URL.createObjectURL(file);
    const formData = new FormData();
    formData.append('file', file);
    setImagePath(base64);
    setAvatarNotFormatted(base64);
    setAvatar(formData);
  };
  return (
    <AvatarContainer>
      <AvatarImage
        onChange={handleChange}
        type="file"
        src={imageSrc}
        alt="avatar"
        id="imageUpload"
        accept=".jpg, .jpeg, .jpe, .png, .bmp"
      />
      <label htmlFor="imageUpload">
        {imagePath || imageSrc || avatar ? (
          <Image id="OpenImgUpload" src={imagePath || avatar || imageSrc} alt="avatar" {...rest} />
        ) : (
          <EmptyImage src={EmptyAvatarImage} alt="pick avatar" id="OpenImgUpload" {...rest} />
        )}
      </label>
    </AvatarContainer>
  );
};

Avatar.propTypes = {
  setAvatar: func.isRequired,
  imageSrc: string,
  setAvatarNotFormatted: func.isRequired,
  avatar: object,
};
Avatar.defaultProps = {
  imageSrc: null,
  avatar: null,
};

const mapStateToProps = createStructuredSelector({
  avatar: (state) => selectUserAvatar(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAvatarNotFormatted: (file) => dispatch(actions.setAvatarNotFormatted(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
