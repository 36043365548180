export const i18n = {
  addSomeInformation: 'Add some information',
  toMakeOurAssist: 'To make our assist more effective we need to know you a bit better',
  addProfilePicture: 'Add profile picture',
  yourName: 'Your Name',
  city: 'City',
  state: 'County',
  saveChanges: 'Save changes',
  dateOfBirth: 'Date of birth',
  yourSurname: 'Your Surname',
  phoneNumber: 'Phone number',
  postalCode: 'Postal code',
  yourAddressLine1: 'Your address line 1',
  yourAddressLine2: 'Your address line 2',
  gpAddress: 'GP address (Optional)',
  continue: 'Continue',
  submit: 'Submit',
  years: 'years',
  stripeSubHeading:
    'We use Stripe to pay you for the sessions you do on Marpe Wellbeing. Stripe will need your bank details as part of the verification process.',
  createPassword: 'Create your password',
  createNewPassword: 'Create your new password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  saveAndLogin: 'Save and login',
  confirmPassword: 'Confirm your password',
  authHeader: 'Let’s start with an email',
  weHaveSendMessageToEmail: 'Thank you for registering with us.',
  thankYouForRequest: 'Thank you for your interest. An email has been sent to you with more information',
  followToInstruction: 'Please follow the instructions in your email to activate your account.',
  alreadyHaveAccount: 'Already have an account? Log in ',
  back: 'Back',
  imUser: 'I am a patient',
  imTherapist: 'I am a therapist/counsellor',
  welcomeTo: 'Welcome to',
  appName: 'Marpe Wellbeing',
  signUp: 'Sign Up',
  signIn: 'Sign In',
  ifHaveAccount: 'Already have an account? Log in',
  edit: 'Edit',
  turnOnNotifications: 'Turn on notifications',
  myReceipts: 'My Income',
  aboutAnApp: 'About an app',
  termsAndConditions: 'Terms and conditions',
  privacyPolicy: 'Privacy policy',
  contactUs: 'Contact us',
  gotIt: 'Got it!',
  hello: 'Hello',
  howAreYou: 'How are you today?',
  showDiary: 'Show diary',
  rateYourOverallFeeling: 'Rate your overall feeling:',
  bad: 'Bad',
  notWell: 'Not well',
  okay: 'Okay',
  great: 'Great',
  addNoteAboutYourFeeling: 'Add a note about your feelings...',
  whatWouldYouLike: 'What would you like to do?',
  bookACounselling: 'Book a counselling or psychotherapy session',
  speakToSomeoneRightNow: 'Speak to someone right now!',
  samaritans: 'Samaritans',
  usefulMaterials: 'Useful materials',
  shortDescriptionYourSelf: 'Add short description of yourself',
  upTo300: 'Up to 300 symbols',
  yourDescription: 'Your description...',
  describeYourSelf: 'About: profile summary',
  jobRole: 'Your job title',
  addRoleTitle: 'Add your job title',
  yourSpecializationKeyWords: 'Describe your Specialisation with key words',
  yourSpecialization: 'Your core Specialisation',
  countOfYourSkills: 'Please hit the enter button after writing each specialisation',
  createTag: 'Type word/phrace and comma after it to create a tag. Backspace/delete to remove.',
  checkingYourData: 'We are checking your data',
  timeToVerify:
    'Thank you for uploading your documents. The Marpe Wellbeing compliance team will now verify these documents.',
  turnNotifications: 'Still need to upload more documents? Click on check document status',
  getTheApp: 'Get the app',
  checkDocsStatus: 'Check documents status',
  attachFiles: 'Attach your files',
  attachCertificationAndOther: 'Attach your documents',
  filesFormat: 'These documents are required for the verification process. pdf, jpeg, png, docs are accepted',
  docsError: 'You need to attach all documents in list',
  dontHaveAllDocs:
    'Don’t have all documents? Don’t worry, upload what you have, you can always login and upload the rest later.',
  attach: 'Attach',
  fileYouHaveAttached: 'Files you’ve attached',
  resendLink: 'Resend link',
  logIn: 'Log in',
  joinAs: 'Join as a therapist',
  logOut: 'Log out',
  accountIsVerified: 'Please set your availability',
  setHoursAndConsult: 'Set the hours and days when you can consult users in Marpe Wellbeing',
  personalInfo: 'Personal info',
  bankingData: 'Banking data',
  receipts: 'Receipts',
  attachedFiles: 'Attached files',
  changePassword: 'Change password',
  editYourProfile: 'Edit your profile',
  registerIfNotYet: 'Don’t have an account? Register',
  forgotPassword: 'Forgot password?',
  sendInstructions: 'Send instructions',
  myAppointments: 'My appointments',
  upcomingAppointments: 'Upcoming appointments',
  pastAppointments: 'Past appointments',
  noUpcomingAppointments: 'No upcoming appointments',
  noPastAppointments: 'No past appointments',
  decline: 'Decline',
  weWillBeInTouch: 'We will be in touch. Please turn on your notifications.',
  addNote: 'Add the note',
  editNote: 'Edit the note',
  saveNote: 'Save',
  joinTheRoom: 'Join the room',
  editAvailability: 'Edit availability',
  editTimeSlot: 'Edit time slot',
  dontRepeat: 'Do not repeat weekly',
  unavailableOnThisDate: 'I’m unavailable on this date',
  saveInterval: 'Save',
  saveAndContinue: 'Save and Continue',
  cantAddAvailForPrevDate: "You can't add availability for previous date",
  validateAvailDate1: "Date can't be empty",
  validateAvailDate2: "Date from can't be bigger or equal than date to",
  addAccountNumber: 'Please enter your bank details',
  accountNumber: 'Account number',
  accountName: 'Account Name',
  sortCode: 'Sort Code',
  delete: 'Delete',
  areYourSure: 'Are you sure you want to log out?',
  areYourSureAppointment: 'Are you sure you want to decline appointment?',
  declineAppointment: 'Decline appointment',
  cancel: 'Cancel',
  cancelAppointment: 'Cancel appointment',
  no: 'No',
  yes: 'Yes',
  appointmentNote: 'Appointment note',
  downloadPdf: 'Download detailed PDF',
  addTheNote: 'Add the note',
  AppointmentNoteText: 'Add the note about appointment...',
  restorationDescription: 'Enter your email address and we’ll send you a link to get back into your account.',
  accepted: 'Accepted',
  rejected: 'Rejected',
  verifying: 'Verifying',
  termsOfUse: 'Terms of use',
  privacyAndPolicy: 'Privacy and policy',
  CounsellorPsychotherapist: 'Counsellor/Psychotherapist',
  ClinicalPsychologist: 'Counselling Psychologist',
  emptyAppointments: 'Do not forget to set up your availability',
  emptyReceipts: "You don't have any receipts yet! Return to this page after you finish your first appointment",
  landingText1: 'How are you really doing?',
  landingText2: 'We genuinely care and do want to know.',
  landingText3:
    'The responses we have gotten have varied from situations around stress, insecurity, worry, depression, anxiety,\n' +
    '        fear, relationships, grief, and so on.',
  landingText4: "At Marpe we'll connect you to a therapist that can help.",
  useEnterKey: 'Please use “Enter” key to separate the tags',
  expressInterest: 'Express your interest',
  letsStartWithTheFollowing: "Let's start with the following",
  adult: 'Adult',
  juvenile: 'Juvenile',
  microError: 'Please, connect your micro and reload this page',
  cameraError: 'Please, connect your camera and reload this page',
  microAndCameraError: 'Please, connect your micro and camera and reload this page'
};
