import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { elementType, object } from 'prop-types';

const PrivateRoute = ({ component: Component, isAuthenticated, location, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/account', state: { from: location } }} />
    }
  />
);
PrivateRoute.propTypes = {
  component: elementType,
  isAuthenticated: object.isRequired,
  location: object,
};
PrivateRoute.defaultProps = {
  component: null,
  location: null,
};
export default PrivateRoute;
