import styled from 'styled-components';

export const EditProfileFormWrapper = styled.div`
  background: white;
  width: 100%;
  padding: 24px 24px;
  position: relative;
  form {
    padding: 32px 48px;
    display: flex;
    flex-direction: column;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  button {
    background: rgba(95, 62, 212, 0.05);
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    padding: 8px 24px;
    font-size: 16px;
    color: #4f2ad7;
    border: none;
    &:hover {
      background: #4f2ad7;
      color: white;
    }
  }
`;
export const CustomInputPhone = styled.div`
  display: flex;
  height: 115px;
  margin: 0 16px;
  width: 100%;
  @media (min-width: 1024px) {
    flex: 0 45%;
  }
`;
export const AddPersonalInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 16px;
    color: black;
    line-height: 22px;
    display: flex;
    justify-content: center;
    a {
      color: #999696;
      text-decoration: none;
    }
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: black;
  }
`;
export const AddInfoAboutYourSelfSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  p {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const AddDocumentsSection = styled.div``;
