import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { DashboardWrapper } from '../../pages/Dashboard/DashboardPageStyles';
import LeftBar from '../../components/Dashboard/LeftBar/LeftBar';
import EditProfile from '../../pages/Dashboard/EditProfilePage/EditProfile';
import RightBar from '../../components/Dashboard/RightBar/RightBar';
import ReceiptsPage from '../../pages/Dashboard/ReceiptsPage/ReceiptsPage';

const DashboardPage = lazy(() => import('../../pages/Dashboard/DashboardPageContainer'));
const CalendarPage = lazy(() => import('../../pages/Dashboard/CalendarPage/CalendarPageContainer'));
const VideoPage = lazy(() => import('../../pages/Dashboard/VideoPage/VideoPageContainer'));
const DashboardRouter = ({ ...rest }) => (
  <Switch>
    <DashboardWrapper>
      {!rest.location.pathname.includes('dashboard/video') && (
        <>
          <LeftBar />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/dashboard/edit" component={EditProfile} />
          <Route exact path="/dashboard/calendar" component={CalendarPage} />
          <Route exact path="/dashboard/receipts" component={ReceiptsPage} />
          <RightBar {...rest} />
        </>
      )}
      <Route exact path="/dashboard/video" component={VideoPage} />
    </DashboardWrapper>
  </Switch>
);
export default DashboardRouter;
