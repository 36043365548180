import styled from 'styled-components';

export const RightBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  max-width: 312px;
  background: white;
  align-items: center;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;
`;
export const EditButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
`;
export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Avatar = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 8px;
  margin-bottom: 24px;
  object-fit: cover;
`;
export const FullName = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #1c1f24;
  margin-bottom: 10px;
`;
export const Specialization = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1c1f24;
  opacity: 0.5;
  span {
    &:not(:last-child) {
      &:after {
        content: ', ';
        color: #1c1f24;
      }
    }
  }
`;
export const ReceiptsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(243, 243, 244);
  width: 100%;
  height: 80px;
  cursor: pointer;
  border-radius: 16px;
  margin-bottom: 40px;
  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1c1f24;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      margin-right: 17px;
    }
  }
`;
export const ShortNote = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #1c1f24;
  margin-bottom: 45px;
  max-width: 206px;
`;
export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 800;
  }
`;
export const EditAvailabilityButton = styled.button`
  width: 168px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(95, 62, 212, 0.05);
  border-radius: 100px;
  color: #5f3ed4;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  border: none;
`;
