import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { func, array } from 'prop-types';
import { i18n } from '../../../constants/i18n';
import { documentsFormValidation } from '../../../utils/validators';
import { FormTitle } from '../../../pages/Dashboard/EditProfilePage/EditProfileStyles';
import { DocumentsProfileFormWrapper } from './EditDocumentsFormStyles';
import { AddDocumentsSection } from '../EditProfileForm/EditProfileFormStyles';
import AddDocumentsForm from '../../SignUp/SignUpStepTwo/AddDocuments/AddDocumentsForm/AddDocumentsForm';

const EditDocumentsForm = ({ saveDocuments, documents, getDocuments, removeDocument }) => {
  useEffect(() => {
    getDocuments();
  }, []);
  return (
    <DocumentsProfileFormWrapper>
      <FormTitle>{i18n.attachedFiles}</FormTitle>
      {/*<p>{i18n.filesFormat}</p>*/}
      {documents && (
        <AddDocumentsSection id="addDocumentsSection">
          <AddDocumentsForm
            documents={documents}
            activateUser={() => {}}
            saveDocuments={saveDocuments}
            removeDocument={removeDocument}
          />
        </AddDocumentsSection>
      )}
    </DocumentsProfileFormWrapper>
  );
};

EditDocumentsForm.propTypes = {
  saveDocuments: func.isRequired,
  getDocuments: func.isRequired,
  removeDocument: func.isRequired,
  documents: array,
};
EditDocumentsForm.defaultProps = {
  documents: null,
};

export default reduxForm({
  form: 'editForm',
  destroyOnUnmount: false,
  validate: documentsFormValidation,
})(EditDocumentsForm);
