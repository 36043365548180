import { createSelector } from 'reselect';
import get from 'lodash/get';
import { NAME } from '../redux/authorization/authorization';
import { documentsEnumTypes } from '../constants/formsConstants';
import { STATES_CONST } from '../constants/states';

const selectAuth = (state) => state[NAME];

export const selectAuthError = createSelector(selectAuth, ({ error }) => error);

export const fetchingState = createSelector(selectAuth, ({ isFetching }) => isFetching);

export const selectUserToken = createSelector(selectAuth, ({ token }) => token);

export const selectUserAvatar = createSelector(selectAuth, ({ avatarImageNotFormatted }) => avatarImageNotFormatted);

export const selectStripeApiKey = createSelector(selectAuth, ({ stripeApiKey }) => stripeApiKey);

export const selectRestorationEmail = createSelector(selectAuth, ({ restoredEmail }) => restoredEmail);

export const selectSignUpEmail = createSelector(selectAuth, ({ signUpEmail }) => signUpEmail);

export const selectPostCodes = createSelector(selectAuth, ({ postCodes }) => {
  const result =
    postCodes &&
    postCodes.map((el) => {
      return { label: el };
    });
  return result || [];
});

export const selectTherapistData = createSelector(selectAuth, ({ therapistData }) => {
  if (therapistData) {
    const specializations = therapistData.specializations?.split(',');
    const phone = get(therapistData, 'phone') && therapistData.phone?.substring(3);
    const state = STATES_CONST.find((el) => therapistData.state === el.code);
    return { ...therapistData, specializations, phone, state };
  }
  return null;
});

export const selectTherapistDocuments = createSelector(selectAuth, ({ therapistDocument }) => therapistDocument);

export const selectDocuments = createSelector(selectAuth, ({ documents }) => {
  const formattedDocuments =
    documents &&
    documentsEnumTypes.reduce((acc, curr, index) => {
      const currentEL = documents.find((el) => el.documentType === curr.documentType);
      if (currentEL) {
        if (curr.documentType === currentEL.documentType) {
          return [...acc, currentEL];
        }
      }
      return [...acc, curr];
    }, []);
  return documents ? formattedDocuments : documentsEnumTypes;
});
