import axios, { post, get, put } from 'axios';
import * as _ from 'lodash';

import {
  AUTH_URL,
  THERAPIST_SIGN_UP_EMAIL,
  RESTORE_INIT,
  THERAPIST_ACTIVATE_EMAIL,
  THERAPIST_UPLOAD_IMAGE,
  DOCUMENTS,
  CURRENT_THERAPIST,
  UPDATE_THERAPIST,
  RESTORE_FINISH,
  STRIPE_PUBLIC_KEY,
  DOCUMENTS_REMOVE,
  DOCUMENTS_ALL,
  GET_POSTCODES,
  INTEREST_EXPRESSIONS,
} from '../constants/api';
import { getFromLocalStorage, getFromLocalStorageAsync } from './localStor';

export const authorize = async (username, password) => {
  try {
    const result = await post(AUTH_URL, { username, password, rememberMe: false });
    return result.data.id_token;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail') || _.get(error, 'response.data.title');
    throw errorData;
  }
};

export const restore = async (email) => {
  try {
    const result = await post(RESTORE_INIT, email);
    return result;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail') || _.get(error, 'response.data.title');
    throw errorData;
  }
};
export const restoreFinishUtil = async (newPassword, key) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await post(
      RESTORE_FINISH,
      { newPassword, key },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return result;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail') || _.get(error, 'response.data.title');
    throw errorData;
  }
};

export const createUser = async (email) => {
  try {
    const result = await post(THERAPIST_SIGN_UP_EMAIL, { email });
    return { id_token: result.data.id_token, email };
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail') || _.get(error, 'response.data.title');
    throw errorData;
  }
};

export const sendToActivation = async (userDetails, key) => {
  try {
    const result = await post(`${THERAPIST_ACTIVATE_EMAIL}?key=${key}`, { ...userDetails });
    return result.data;
  } catch (error) {
    const errorData =
      _.get(error, 'response.data.detail') ||
      _.get(error, 'response.data.title') ||
      _.get(error, 'response.data.message');
    throw errorData;
  }
};

export const updateTherapistUtil = async (payload) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await put(UPDATE_THERAPIST, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    const errorData =
      _.get(error, 'response.data.detail') ||
      _.get(error, 'response.data.title') ||
      _.get(error, 'response.data.message');
    throw errorData;
  }
};

export const uploadAvatarUtil = async (payload) => {
  const token = await getFromLocalStorageAsync('token');
  try {
    const result = await post(THERAPIST_UPLOAD_IMAGE, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};

export const uploadDocFilesUtil = async (payload) => {
  const token = await getFromLocalStorageAsync('token');
  try {
    const result = await post(DOCUMENTS, payload.file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      params: {
        type: payload.type,
      },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const removeDocumentUtil = async (payload) => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await axios.delete(DOCUMENTS_REMOVE(payload), {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};

export const getCurrentTherapist = async () => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(CURRENT_THERAPIST, { headers: { Authorization: `Bearer ${token}` } });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getAllDocumentsUtil = async () => {
  const token = await getFromLocalStorage('token');
  try {
    const result = await get(DOCUMENTS_ALL, { headers: { Authorization: `Bearer ${token}` } });
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getStripeTokenUtil = async () => {
  try {
    const result = await get(STRIPE_PUBLIC_KEY);
    return result.data.key;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const getPostCodesUtil = async (postCode) => {
  try {
    const result = await get(GET_POSTCODES(postCode));
    return result.data.result;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
export const contactUsUtil = async (payload) => {
  try {
    const result = await post(INTEREST_EXPRESSIONS, payload);
    return result.data;
  } catch (error) {
    const errorData = _.get(error, 'response.data.detail', null) || _.get(error, 'response.data.title', null);
    throw errorData;
  }
};
