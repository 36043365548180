import React from 'react';
import { object, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar as ProfileAvatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Avatar,
  DatePickerWrapper,
  EditAvailabilityButton,
  EditButton,
  FullName,
  Header,
  ProfileInfo,
  ReceiptsWrapper,
  RightBarWrapper,
  Specialization,
} from './RightBarStyles';
import DatePicker from './DatePicker/DatePicker';
import { selectTherapistData } from '../../../selectors/authorizationSelector';
import { selectEventData } from '../../../selectors/dashboardSelector';
import { actions } from '../../../redux/dashboard/dashboard';
import Edit from '../../../assets/images/editIcon.svg';
import { i18n } from '../../../constants/i18n';
import receiptsImage from '../../../assets/images/receipts.png';
import profileImage from '../../../assets/images/profile.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '88px',
    height: '88px',
    borderRadius: '100px',
    marginBottom: '25px',
    fontSize: '26px',
    fontWeight: 'bold',
    color: '#5F3ED4',
    backgroundColor: 'rgba(95,62,212,0.05)',
  },
}));

const RightBar = ({ therapistData, getCalendarData, calendarEventData, setSelectedWeek, ...rest }) => {
  const classes = useStyles();
  if (!therapistData || rest.history.location.pathname.includes('dashboard/edit')) {
    return null;
  }
  const { firstName, lastName, imageS3Key, specializations } = therapistData;
  const isCalendarPage = rest.history.location.pathname.includes('dashboard/calendar');

  return (
    <RightBarWrapper>
      <Header>
        <Link to="/dashboard/edit">
          <EditButton type="button">
            <img src={Edit} alt="edit" />
          </EditButton>
        </Link>
      </Header>
      {therapistData ? (
        <ProfileInfo>
          {imageS3Key ? (
            <Avatar src={imageS3Key} alt="avatar" />
          ) : (
            <ProfileAvatar className={classes.root}>
              <img src={profileImage} alt="empty profile" />
            </ProfileAvatar>
          )}
          <FullName>{`${firstName.substring(0, 15)} ${
            (lastName.length || firstName.length) >= 15 ? `${lastName.substring(0, 15)}...` : lastName
          }`}</FullName>
          <Specialization>
            {specializations?.map((spec) => (
              <span key={spec}>{spec}</span>
            ))}
          </Specialization>
          {/*<ShortNote>{description}</ShortNote>*/}
        </ProfileInfo>
      ) : null}
      <ReceiptsWrapper>
        <Link to="/dashboard/receipts">
          <img src={receiptsImage} alt="receipts" />
          {i18n.myReceipts}
        </Link>
      </ReceiptsWrapper>
      <DatePickerWrapper>
        {!isCalendarPage && (
          <Link to="/dashboard/calendar">
            <EditAvailabilityButton type="button">{i18n.editAvailability}</EditAvailabilityButton>
          </Link>
        )}
        <DatePicker
          setSelectedWeek={setSelectedWeek}
          calendarEventData={calendarEventData}
          getCalendarData={getCalendarData}
          {...rest}
        />
      </DatePickerWrapper>
    </RightBarWrapper>
  );
};
RightBar.propTypes = {
  therapistData: object,
  getCalendarData: func,
  calendarEventData: object,
  setSelectedWeek: func,
};
RightBar.defaultProps = {
  calendarEventData: [],
  getCalendarData: () => {},
  setSelectedWeek: () => {},
  therapistData: null,
};
const mapStateToProps = createStructuredSelector({
  therapistData: (state) => selectTherapistData(state),
  calendarEventData: (state) => selectEventData(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedWeek: (payload) => dispatch(actions.setSelectedWeek(payload)),
  getCalendarData: (payload) => dispatch(actions.getCalendarData.request(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
