import { config } from './Constants';

export const { BASE_URL } = config;
export const AUTH_URL = `${BASE_URL}/api/authenticate`;
export const RESTORE_INIT = `${BASE_URL}/api/account/reset-password/init`;
export const RESTORE_FINISH = `${BASE_URL}/api/account/reset-password/finish`;
export const THERAPIST_SIGN_UP_EMAIL = `${BASE_URL}/api/therapists/register`;
export const THERAPIST_ACTIVATE_EMAIL = `${BASE_URL}/api/activate-therapist`;
export const THERAPIST_UPLOAD_IMAGE = `${BASE_URL}/api/therapists/photo`;
export const DOCUMENTS = `${BASE_URL}/api/verification-documents`;
export const DOCUMENTS_ALL = `${BASE_URL}/api/therapists/verification-documents/my`;
export const DOCUMENTS_REMOVE = (id) => `${BASE_URL}/api/verification-documents/${id}`;
export const CURRENT_THERAPIST = `${BASE_URL}/api/therapists/me`;
export const STRIPE_PUBLIC_KEY = `${BASE_URL}/api/payment/stripe-public-key`;
export const UPDATE_THERAPIST = `${BASE_URL}/api/therapists`;
export const THERAPIST_RECURRENT_AVAILABILITY = `${BASE_URL}/api/therapists/calendar`;
export const THERAPIST_APPOINTMENTS = `${BASE_URL}/api/appointments/my/therapists`;
export const THERAPIST_APPOINTMENTS_ID = (id) => `${BASE_URL}/api/appointments/${id}`;
export const THERAPIST_CALENDAR_ME = `${BASE_URL}/api/therapists/calendar/me`;
export const THERAPIST_CALENDAR_CUSTOM = `${BASE_URL}/api/therapists/calendar/custom`;
export const DELETE_CUSTOM_AVAILABILITY = (id) => `${BASE_URL}/api/therapists/calendar/custom/${id}`;
export const CONNECT_TO_ROOM = (id) => `${BASE_URL}/api/appointments/${id}/connect`;
export const CANCEL_APPOINTMENT = (id) => `${BASE_URL}/api/appointments/${id}/cancel`;
export const DEEP_LINK_URL = `${BASE_URL}/apple-app-site-association`;
export const CHANGE_PASSWORD = `${BASE_URL}/api/account/change-password`;
export const RECEIPTS_THERAPIST = `${BASE_URL}/api/payment/statistics-therapist`;
export const APPOINTMENTS_NOTE = (id) => `${BASE_URL}/api/appointments/${id}/note`;
export const RECEIPT_PDF = (id) => `${BASE_URL}/api/payment/${id}/therapist`;
export const GET_POSTCODES = (postCode) => `https://api.postcodes.io/postcodes/${postCode}/autocomplete`;
export const INTEREST_EXPRESSIONS = `${BASE_URL}/api/interest-expressions`;
